import React from "react";

import { svgimages } from "../../utils/svgimages";
import { Box, Chip, Link, Typography } from "@mui/material";
import { ClientDetails } from "../../types/clientDetails";
import { Theme, useTheme } from "@mui/system";

const styles = (theme: Theme) => ({
  root: {
    borderBottom: 1,
    borderColor: "#E6EBED",
    [theme.breakpoints.down("xl")]: {
      height: "17vh",
    },
    [theme.breakpoints.up("xl")]: {
      height: "14vh",
    },
    textAlign: "left",
  },
  details: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  welcome: {
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 36,
    },
  },
  welcomeSmaller: {
    fontSize: 18,
  },
});

export default function Dashboard({
  menuClickHandler,
  clientDetails,
}: {
  menuClickHandler: any;
  clientDetails: ClientDetails;
}) {
  const handleEditClick = () => {
    const navItem = { id: 7, name: "Edit", icon: "edit" };
    menuClickHandler(navItem);
  };

  const theme = useTheme();
  const classes = styles(theme);

  return (
    <Box sx={classes.root}>
      <Box paddingLeft={5} paddingTop={2}>
        <Typography color={"gray"} fontSize={12}>
          Home
        </Typography>
        <Typography
          sx={
            clientDetails?.full_name?.length > 39
              ? classes.welcomeSmaller
              : classes.welcome
          }
        >
          Welcome, {clientDetails?.full_name || ""}
        </Typography>
      </Box>
      <Box sx={classes.details} right={30} top={10} position={"absolute"}>
        <Typography color={"gray"} fontSize={12}>
          Your Details (
          <Link
            component="button"
            color="rgba(222, 134, 70, 1);"
            onClick={handleEditClick}
          >
            edit
          </Link>
          )
        </Typography>
        <Box marginTop={1}>
          {clientDetails.notification_preference?.includes("mobile") ? (
            <>
              <Typography fontSize={12}>
                <img
                  height={15}
                  src={svgimages["message"]}
                  alt="SVG054messageI147"
                  className="email-image"
                />
                &nbsp; {clientDetails?.email || ""}
              </Typography>
              <Typography fontSize={12}>
                <img
                  height={15}
                  src={svgimages["telephone"]}
                  alt="SVG054messageI147"
                  className="tel-image"
                />
                &nbsp; {clientDetails?.phone || ""}
              </Typography>
              <Chip
                sx={{ marginTop: 1, bgcolor: "#FCF3ED" }}
                size="small"
                label="PREFERRED"
              />
            </>
          ) : (
            <>
              <Typography fontSize={12}>
                <img
                  height={15}
                  src={svgimages["telephone"]}
                  alt="SVG054messageI147"
                  className="tel-image"
                />
                &nbsp; {clientDetails?.phone || ""}
              </Typography>
              <Typography fontSize={12}>
                <img
                  height={15}
                  src={svgimages["message"]}
                  alt="SVG054messageI147"
                  className="email-image"
                />
                &nbsp; {clientDetails?.email || ""}
              </Typography>
              <Chip
                sx={{ marginTop: 1, bgcolor: "#FCF3ED" }}
                size="small"
                label="PREFERRED"
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
