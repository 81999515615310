import { Button, Theme } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React from "react";

const styles = (theme: Theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    color: "#E6EBED",
    bgcolor: "white",
  },
});

const CategoryNav = ({
  index,
  totalCategories,
  handleCategorySelectClick,
}: {
  index: number;
  totalCategories: number;
  handleCategorySelectClick: (index: number) => void;
}): JSX.Element => {
  const classes = styles(useTheme());

  return (
    <Box sx={classes.root}>
      <Button
        onClick={() => {
          handleCategorySelectClick(index - 1);
        }}
        disabled={index === 0}
      >
        Previous
      </Button>
      <Button
        onClick={() => {
          handleCategorySelectClick(index + 1);
        }}
        disabled={index === totalCategories - 1}
      >
        Next
      </Button>
    </Box>
  );
};

export default CategoryNav;
