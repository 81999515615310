import React, { useState, useEffect } from "react";
import { Layout } from "../../../Style";
import TextView from "../Components/TextView/component";
import DropdownView from "../Components/Dropdown/component";
import DateView from "../Components/DateView/component";
import SelectOneFromOptions from "../Components/SelectOneFromOptions/component";
import { Box, CircularProgress, Typography } from "@mui/material";
import Array from "../Array/component";

enum FormEntryType {
  TextEntry = 1,
  DateEntry = 2,
  OptionEntry = 3,
  ComboBoxEntry = 4,
  TextEditorEntry = 5,
  LabelEntry = 6,
  MultiSelectEntry = 7,
  AddItemsEntry = 8,
  Checkbox = 9,
  VerticalLayoutEntry = 10,
  HorizontalLayoutEntry = 11,
  CategoryEntry = 12,
  CategorizationEntry = 13,
  OccupierEntry = 14,
  AddNewEntry = 15,
  SummaryEntry = 16,
}

interface FormItem {
  ItemType: number;
  Label: string;
  Narrative?: string;
  FullReferentialPath: string;
  Options?: string[];
  AnswerValue?: string;
  AddItems?: FormItemAddItems[];
}

interface FormItemAddItems {
  Label: string;
  Dob?: string;
  Relationship?: string;
  ShowDetails: boolean;
  FormItems: FormItem[];
}

export default function FormItemComponent({
  setDisableSubmit,
  item,
  losingFocus,
  setLosingFocus,
  handleSaved,
}: {
  setDisableSubmit: (value: boolean) => void;
  item: FormItem;
  losingFocus: boolean;
  setLosingFocus: (value: boolean) => void;
  handleSaved: any;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [formItem, setFormItem] = useState<FormItem>(item);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setFormItem(item);
      setLoading(false);
    }, 200);
  }, [item]);

  return (
    <Layout>
      <Box minHeight={"30vh"} padding={2}>
        <div>
          <div className="hidden">
            <h1 data-testid="formItemRefPath">
              {formItem!.FullReferentialPath}
            </h1>
          </div>
          <Typography data-testid="formItemLabel" fontWeight={"bold"}>
            {formItem!.Label}
          </Typography>
          <Typography
            color="#8199A6"
            marginTop={2}
            data-testid="formItemNarrative"
          >
            {formItem!.Narrative}
          </Typography>
          <Box marginTop={2}>
            {loading ? (
              <Box sx={{ display: "flex" }}>
                <></>
              </Box>
            ) : formItem!.ItemType === FormEntryType.TextEntry ? (
              <TextView
                setDisableSubmit={setDisableSubmit}
                item={formItem!}
                handleSaved={handleSaved}
                losingFocus={losingFocus}
                showLabel={false}
              />
            ) : formItem!.ItemType === FormEntryType.OptionEntry ? (
              <DropdownView
                item={formItem!}
                handleSaved={handleSaved}
                losingFocus={losingFocus}
                showLabel={false}
                multiSelect={
                  formItem!.FullReferentialPath ===
                  "initial_questionnaire[0].what_is_use_of_funds"
                }
              />
            ) : formItem!.ItemType === FormEntryType.AddItemsEntry ? (
              <Array
                item={formItem!}
                handleSaved={handleSaved}
                losingFocus={losingFocus}
                setLostFocus={setLosingFocus}
              />
            ) : formItem!.ItemType === FormEntryType.ComboBoxEntry ? (
              <SelectOneFromOptions
                item={formItem!}
                handleSaved={handleSaved}
                losingFocus={losingFocus}
                showLabel={false}
              />
            ) : formItem!.ItemType === FormEntryType.DateEntry ? (
              <DateView
                item={formItem!}
                handleSaved={handleSaved}
                losingFocus={losingFocus}
                showLabel={false}
              />
            ) : (
              <></>
            )}
          </Box>
        </div>
      </Box>
    </Layout>
  );
}
