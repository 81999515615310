import { FiberManualRecord } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box, Theme, useTheme } from "@mui/system";
import React, { useEffect } from "react";
import Updates from "../../types/updates";
import { setUpdatesSeen } from "../../utils/updates";
import { UserData } from "../../types/user";

const displayDate = (date: Date): string => {
  const zeroPad = (num: number, places: number) =>
    String(num).padStart(places, "0");

  return `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()} ${zeroPad(date.getHours(), 2)}:${zeroPad(
    date.getMinutes(),
    2
  )}`;
};

const styles = (theme: Theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "75%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
});

const UpdatesPage = ({ updates }: { updates: Updates[] }): JSX.Element => {
  useEffect(() => {
    const userDetails = JSON.parse(
      localStorage.getItem("user") || "{}"
    ) as UserData;

    if (updates?.length > 0) {
      setUpdatesSeen(userDetails.matter_id);
    }
  }, [updates]);

  const classes = styles(useTheme());

  return (
    <Box bgcolor="#E6EBED">
      <Box padding={2}>
        <Box
          sx={{ borderColor: "#E6EBED" }}
          borderBottom={1}
          bgcolor="white"
          textAlign="left"
        >
          <Box padding={3}>
            <Typography fontWeight={"bold"} fontSize={14}>
              Updates
            </Typography>
          </Box>
        </Box>
        <Box bgcolor="white" textAlign="left">
          <Box padding={3} sx={classes.container}>
            {updates?.map((item, index) => (
              <Box
                key={index}
                paddingBottom={2}
                borderBottom={1}
                sx={{ borderColor: "#E6EBED" }}
              >
                <Box key={index} padding={2} display="inline-flex">
                  <FiberManualRecord
                    fontSize="small"
                    data-testid={item.seen ? "read-icon" : "unread-icon"}
                    sx={{ color: item.seen ? "#E6EBED" : "#0DBC29" }}
                  />
                  <Typography color="#8199A6" marginLeft={1}>
                    {displayDate(new Date(item.date))}
                  </Typography>
                </Box>
                <Box
                  paddingLeft={2}
                  paddingRight={2}
                  paddingTop={0}
                  paddingBottom={2}
                >
                  <Typography
                    sx={{ whiteSpace: "pre-line" }}
                    color="#8199A6"
                    marginLeft={1}
                  >
                    {item.content}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdatesPage;
