import * as React from "react";
import { Layout } from "../../../../Style";
import { FormItem } from "../../../../types/formTemplate";
import { Box } from "@mui/system";
import { Button, Grid, Typography } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import dayjs, { Dayjs } from "dayjs";
import { UserData } from "../../../../types/user";
import updateReferentialPaths from "../../../../utils/updateReferentialPaths";
import { DatePicker } from "@mui/x-date-pickers";

export default function DateView({
  item,
  handleSaved,
  losingFocus,
  showLabel,
}: {
  item: FormItem;
  handleSaved: any;
  losingFocus: boolean;
  showLabel: boolean;
}) {
  const [formItem, setFormItem] = React.useState<FormItem>(item);
  // const [answerValue, setAnswerValue] = React.useState<string>("");
  const [value, setValue] = React.useState<Dayjs | null>(
    item.AnswerValue ? dayjs(item.AnswerValue, "DD/MM/YYYY") : null
  );

  React.useEffect(() => {
    let answerValue = item.AnswerValue;
    if (!answerValue) {
      answerValue = "";
    }
    setValue(dayjs(answerValue, "DD/MM/YYYY"));
    setFormItem(item);
  }, [item]);

  const saveAnswer = async () => {
    let answerValue = value?.format("DD/MM/YYYY");
    if (!answerValue) {
      answerValue = "";
    }
    if (answerValue === item.AnswerValue) {
      handleSaved(item);
    } else {
      const userDetails = JSON.parse(
        localStorage.getItem("user") || "{}"
      ) as UserData;
      console.log("saving answer");
      await updateReferentialPaths(
        userDetails.matter_id,
        item.FullReferentialPath,
        value!.format("DD/MM/YYYY")
      );
      console.log("saved answer");
      let newItem = item;
      newItem.AnswerValue = answerValue;
      handleSaved(newItem);
    }
  };

  React.useEffect(() => {
    if (losingFocus) {
      saveAnswer();
    }
  }, [losingFocus]);

  return (
    <Grid container>
      {showLabel ? (
        <Grid item xs={5}>
          <Typography paddingBottom={2} data-testid="formItemLabel">
            {item!.Label}
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={7}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            data-testid="date-input"
            format="DD/MM/YYYY"
            defaultValue={dayjs(value, "DD/MM/YYYY")}
            onChange={(newValue) => setValue(newValue)}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
