import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const THEME = createTheme({
  typography: {
    fontFamily: `Roboto`,
    fontSize: 12,
    body1: {
      color: "#04334E",
    },
  },
  palette: {
    text: {
      primary: "#04334E",
    },
    primary: {
      main: "#04334E",
    },
    secondary: {
      main: "#FCF3ED",
    },
    success: {
      main: "#0DBC29",
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        selected: {
          backgroundColor: "#FCF3ED",
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={THEME}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
