import {
  AnswerValues,
  ElementOptions,
  FormElementDisplay,
  FormEntryType,
  FormItem,
  FormProperty,
  NovexAnswerObject,
  AddItemDisplayModel,
  FormItemAddItems,
} from "../types/formTemplate.d";
import {
  CurrentValueModel,
  Dictionary,
  AddItemsDisplayModel,
  FormTemplateData,
} from "../types/formTemplate.d";

import { Constants } from "../utils/constants";

class FormTemplateService {
  private static instance: FormTemplateService | null = null;

  private formItems: FormItem[] = [];
  private narrative: string = "";
  private cats: FormElementDisplay[] = [];
  private currentValues: CurrentValueModel[] = [];
  public formTemplateData: FormTemplateData | null = null;
  private formVariableElements: FormElementDisplay[] = [];
  private answerValues: AnswerValues[] = [];
  private formElements: FormElementDisplay[] = [];
  private required: string[] = [];

  static getInstance(): FormTemplateService {
    if (!FormTemplateService.instance) {
      FormTemplateService.instance = new FormTemplateService();
    }
    return FormTemplateService.instance;
  }

  private valueList: CurrentValueModel[] = [];

  private constructor() {
    // Initialize your service here
  }

  async processCurrentValues(data: any) {
    this.valueList = [];
    let value = "";
    let keyPrefix = "";
    let objectPrefix = "";

    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        const val = data[key];
        if (typeof val === "object") {
          if (Array.isArray(val)) {
            objectPrefix = atob(key);
            for (var i = 0; i < val.length; i++) {
              keyPrefix = objectPrefix + "[" + i.toString() + "].";
              for (var item in val[i]) {
                value = val[i][item];
                await this.addKeyValue(item, value, keyPrefix);
              }
            }
            keyPrefix = "";
            objectPrefix = "";
          }
        } else {
          await this.addKeyValue(key, val, keyPrefix);
        }
      }
    }

    return this.valueList;
  }

  async addKeyValue(key: string, val: string, keyPrefix: string) {
    key = keyPrefix + atob(key);
    let scope = key;
    let value = val;

    const curr: CurrentValueModel = {
      scope: scope,
      referential_path: key,
      value: value,
      label: "",
      category: "",
    };

    const current = this.valueList.find((b) => b.referential_path === key);
    if (!current) {
      this.valueList.push(curr);
    }
  }

  async processObject(
    data: any,
    arrayIndex: number = -1,
    keyPrefix: string = "",
    objectPrefix: string = ""
  ) {
    let value = "";
    let scope = "";

    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        const val = data[key];
        if (typeof val === "object") {
          if (Array.isArray(val)) {
            objectPrefix = key;
            arrayIndex = -1;
          }

          await this.processObject(val, arrayIndex, keyPrefix, objectPrefix);
        } else {
          key = keyPrefix + atob(key);
          scope = key;
          value = val;

          const curr: CurrentValueModel = {
            scope: scope,
            referential_path: key,
            value: value,
            label: "",
            category: "",
          };

          const current = this.valueList.find(
            (b) => b.referential_path === key
          );
          if (!current) {
            this.valueList.push(curr);
          }
        }
      }
    }

    return this.valueList;
  }

  async elementForRule(
    scope: string,
    formElements: FormElementDisplay[],
    rules: FormElementDisplay[]
  ): Promise<FormElementDisplay | null> {
    for (const element of formElements) {
      await this.checkAddElement(scope, element, rules);

      if (element.addItems) {
        for (const subElements of element.addItems.Items) {
          if (subElements.ItemDetails) {
            for (const subId of subElements.ItemDetails) {
              await this.checkAddElement(scope, subId, rules);

              if (subId.elements) {
                const subSubElement = await this.elementForRule(
                  scope,
                  subId.elements,
                  rules
                );
                if (subSubElement) {
                  await this.checkAddElement(scope, subSubElement, rules);
                }
              }
            }
          }
        }
      }

      if (element.elements) {
        for (const subElements of element.elements) {
          await this.checkAddElement(scope, subElements, rules);

          if (subElements.elements) {
            const subSubElement = await this.elementForRule(
              scope,
              subElements.elements,
              rules
            );
            if (subSubElement) {
              await this.checkAddElement(scope, subSubElement, rules);
            }
          }
        }
      }
    }

    return null;
  }

  async checkAddElement(
    scope: string,
    element: FormElementDisplay,
    rules: FormElementDisplay[]
  ) {
    const modScope = scope.replace("#/properties/", "");

    if (element.DependScope === scope) {
      if (!rules.includes(element)) rules.push(element);
    }

    if (element.options && element.options.conditionals) {
      for (const conditional of element.options.conditionals) {
        if (conditional.depends_on === modScope) {
          if (!rules.includes(element)) rules.push(element);
        }
      }
    }
  }

  async getCats(): Promise<FormElementDisplay[]> {
    return this.cats;
  }

  async getFormItems(currentCategory: number): Promise<FormItem[]> {
    // Assuming this.Cats gets populated somewhere
    const cats = await this.getCategories();

    const categoryElements = cats![currentCategory];

    this.formItems = [];

    for (const formElement of categoryElements.elements || []) {
      const showRule = await this.checkShowRuleCondition(formElement);
      const showConditional = await this.checkOptionConditionals(formElement);

      if (
        formElement.options &&
        formElement.options.referential_path ===
          "power_of_attorney[0].first_name"
      ) {
        console.log("elements");
      }

      if (showRule && showConditional) {
        await this.addEntry(formElement);

        if (formElement.elements) {
          for (const formItem of formElement.elements) {
            if (
              formItem.options &&
              formItem.options.full_referential_path ===
                "power_of_attorney[0].first_name"
            ) {
              console.log("elements");
            }
            const innerShowRule = await this.checkShowRuleCondition(formItem);
            const innerShowConditional = await this.checkOptionConditionals(
              formItem
            );

            if (innerShowRule && innerShowConditional) {
              await this.addEntry(formItem);
            }
          }
        }
      }
    }

    return this.formItems;
  }

  async addEntry(formItem: FormElementDisplay): Promise<void> {
    if (formItem.options && formItem.options.narrative) {
      this.narrative = formItem.label;
      return;
    }

    let item: FormItem | undefined;

    switch (formItem.entryType) {
      case FormEntryType.ComboBoxEntry: {
        item = {
          ItemType: formItem.enums && formItem.enums.length > 2 ? 3 : 4,
          Label: formItem.label,
          FullReferentialPath: formItem.options!.full_referential_path,
          Options: formItem.enums,
        };
        if (
          formItem.currentValue !== null &&
          formItem.currentValue !== undefined
        ) {
          item.AnswerValue = formItem.currentValue.toString();
        }
        if (this.narrative.length > 0) {
          item.Narrative = this.narrative;
          this.narrative = "";
        }
        this.formItems.push(item as FormItem);

        break;
      }
      case FormEntryType.TextEntry: {
        item = {
          ItemType: 1,
          Label: formItem.label,
          FullReferentialPath: formItem.options!.full_referential_path,
          IsPhoneNumber: formItem.is_phone_number,
          IsEmail: formItem.is_email,
        };
        if (
          formItem.currentValue !== null &&
          formItem.currentValue !== undefined
        ) {
          item.AnswerValue = formItem.currentValue.toString();
        }
        if (this.narrative.length > 0) {
          item.Narrative = this.narrative;
          this.narrative = "";
        }
        this.formItems.push(item as FormItem);

        break;
      }
      case FormEntryType.DateEntry: {
        item = {
          ItemType: 2,
          Label: formItem.label,
          FullReferentialPath: formItem.options!.full_referential_path,
        };
        if (
          formItem.currentValue !== null &&
          formItem.currentValue !== undefined
        ) {
          item.AnswerValue = formItem.currentValue.toString();
        }
        if (this.narrative.length > 0) {
          item.Narrative = this.narrative;
          this.narrative = "";
        }
        this.formItems.push(item as FormItem);

        break;
      }
      case FormEntryType.AddItemsEntry: {
        if (formItem.label.toLowerCase() === "occupier details") {
          let item = await this.addArrayItemsEntry(formItem);
          this.formItems.push(item as FormItem);
          break;
        }

        await this.addAddItemsEntry(formItem);
        //  this.formItems.push(item as FormItem);
        break;
      }
      case FormEntryType.AddNewEntry: {
        if (formItem.label.toLowerCase() === "occupiers") {
          let item = {
            ItemType: 11,
            Label: formItem.label,
          };
          this.formItems.push(item as FormItem);
        }
        break;
      }
    }
  }

  async addArrayItemsEntry(formItem: FormElementDisplay): Promise<FormItem> {
    let index = 0;
    var item: FormItem = {
      ItemType: 8,
      Label: formItem.label,
    } as FormItem;

    item.ItemType = FormEntryType.AddItemsEntry;

    if (formItem.addItems) {
      item.AddItems = [];
      for (const addItem of formItem.addItems.Items) {
        var addItemEntry: FormItemAddItems = {
          FormItems: [],
        };

        for (const itemDetails of addItem.ItemDetails) {
          if (index > 0) {
            let newLabel = itemDetails.label;
            newLabel = newLabel.replace("your", "their").replace("you", "they");
            itemDetails.label = newLabel;
          }
          itemDetails.Heading = "Applicant " + (index + 1);

          const showRule = await this.checkShowRuleCondition(itemDetails);
          const showConditional = await this.checkOptionConditionals(
            itemDetails
          );

          if (showRule && showConditional) {
            let newItem = await this.addFormEntry(itemDetails);
            if (newItem) {
              addItemEntry.FormItems.push(newItem);
            }
          }
        }

        item.AddItems.push(addItemEntry);
        index++;
      }
    }

    return item;
  }

  async addFormEntry(
    formItem: FormElementDisplay
  ): Promise<FormItem | undefined> {
    let item: FormItem;

    switch (formItem.entryType) {
      case FormEntryType.ComboBoxEntry: {
        item = {
          ItemType: formItem.enums && formItem.enums.length > 2 ? 3 : 4,
          Label: formItem.label,
          FullReferentialPath: formItem.options!.full_referential_path,
          Options: formItem.enums,
        };
        if (
          formItem.currentValue !== null &&
          formItem.currentValue !== undefined
        ) {
          item.AnswerValue = formItem.currentValue.toString();
        }
        if (this.narrative.length > 0) {
          item.Narrative = this.narrative;
          this.narrative = "";
        }
        return item as FormItem;
      }
      case FormEntryType.TextEntry: {
        item = {
          ItemType: 1,
          Label: formItem.label,
          FullReferentialPath: formItem.options!.full_referential_path,
        };
        if (
          formItem.currentValue !== null &&
          formItem.currentValue !== undefined
        ) {
          item.AnswerValue = formItem.currentValue.toString();
        }
        if (this.narrative.length > 0) {
          item.Narrative = this.narrative;
          this.narrative = "";
        }
        return item as FormItem;
      }
      case FormEntryType.DateEntry: {
        item = {
          ItemType: 2,
          Label: formItem.label,
          FullReferentialPath: formItem.options!.full_referential_path,
        };
        if (
          formItem.currentValue !== null &&
          formItem.currentValue !== undefined
        ) {
          item.AnswerValue = formItem.currentValue.toString();
        }
        if (this.narrative.length > 0) {
          item.Narrative = this.narrative;
          this.narrative = "";
        }
        return item as FormItem;
      }
      case FormEntryType.AddItemsEntry: {
        if (formItem.label.toLowerCase() === "occupier details") {
          let item = await this.addArrayItemsEntry(formItem);
          return item as FormItem;
        }

        await this.addAddItemsEntry(formItem);
        //  this.formItems.push(item as FormItem);
        break;
      }
      case FormEntryType.AddNewEntry: {
        if (formItem.label.toLowerCase() === "occupiers") {
          let item = {
            ItemType: 11,
            Label: formItem.label,
          };
          return item as FormItem;
        }
        break;
      }
    }

    return undefined;
  }

  async addAddItemsEntry(formItem: FormElementDisplay): Promise<void> {
    let index = 0;
    let rootName = "";

    if (formItem.addItems) {
      for (const addItem of formItem.addItems.Items) {
        for (const itemDetails of addItem.ItemDetails) {
          if (index === 0) {
            if (itemDetails.options!.full_referential_path) {
              rootName = itemDetails.options.full_referential_path.substring(
                0,
                itemDetails.options.full_referential_path.indexOf("[")
              );
            }
          } else {
            let newLabel = itemDetails.label;
            newLabel = newLabel.replace("your", "their").replace("you", "they");
            itemDetails.label = newLabel;
          }
          itemDetails.Heading = "Applicant " + (index + 1);

          const showRule = await this.checkShowRuleCondition(itemDetails);
          const showConditional = await this.checkOptionConditionals(
            itemDetails
          );

          if (showRule && showConditional) {
            await this.addEntry(itemDetails);
          }
        }

        index++;

        if (index < formItem.addItems.Items.length) {
          const nextClient = formItem.addItems.Items[index];
          const lastNameRef = rootName + "[" + index.toString() + "].last_name";

          for (const itemDetails of nextClient.ItemDetails) {
            if (itemDetails.options!.full_referential_path === lastNameRef) {
              if (!itemDetails.currentValue) {
                const formElementDisplay = {
                  entryType: FormEntryType.AddNewEntry,
                  label: rootName,
                  numAddItems: index,
                };
                await this.addEntry(formElementDisplay as FormElementDisplay);
                return;
              } else if (itemDetails.currentValue.toString().length === 0) {
                const formElementDisplay = {
                  entryType: FormEntryType.AddNewEntry,
                  label: rootName.toUpperCase(),
                  numAddItems: index,
                };
                await this.addEntry(formElementDisplay as FormElementDisplay);
                return;
              }
            }
          }
        }
      }
    }

    return;
  }

  async checkOptionConditionals(element: FormElementDisplay): Promise<boolean> {
    if (element.options) {
      if (element.options.hidden) {
        if (element.options.hidden === true) {
          return false;
        }
      }

      if (element.options.calculation) {
        console.log("Check Calculation");
      }
    }

    return true;
  }

  async checkShowRuleCondition(element: FormElementDisplay): Promise<boolean> {
    if (element.rule === null || element.rule === undefined) {
      return true;
    }

    const rule = element.rule;
    let effect = "SHOW";

    if (rule["effect"] !== null) {
      const b = rule["effect"] as string;
      effect = b;
    }

    if (effect === "HIDE") {
      // console.log("");
    }

    if (rule["condition"] !== null) {
      const c = rule["condition"] as Dictionary<any>;

      if (c["conditions"] !== undefined) {
        const s = c["conditions"];
        const cList = s as Dictionary<any>[];

        const results: boolean[] = [];

        for (const condition of cList) {
          const result = await this.checkRule(condition, effect);
          results.push(result);
        }

        let returnValue = results[0];

        for (const result of results) {
          returnValue = returnValue && result;
        }

        return returnValue;
      } else {
        const res = await this.checkRule(c, effect);
        return res;
      }
    }

    if (effect === "SHOW") return false;
    if (effect === "HIDE") return true;

    return false;
  }

  async checkRule(c: Dictionary<any>, effect: string): Promise<boolean> {
    let scope = "";

    if (c["scope"] !== null) {
      scope = c["scope"] as string;
    }

    if (c["schema"] !== null) {
      const s = c["schema"];
    }

    if (scope.length === 0 && effect === "SHOW") {
      return true;
    }

    if (scope.length === 0 && effect === "HIDE") {
      return false;
    }

    if (scope.length === 0 && effect === "ENABLE") {
      return true;
    }

    if (scope.includes("clientcount")) {
      console.log("clientcount");
    }

    const res = await this.getValueForProperty(scope);

    if (res !== null) {
      if (c["schema"].enum?.includes(res)) {
        if (effect === "SHOW") {
          return true;
        }

        if (effect === "HIDE") {
          return false;
        }
      }
    }

    return false;
  }

  async checkShowRuleCategoryCondition(
    element: FormElementDisplay
  ): Promise<boolean> {
    if (!element.rule) {
      return true;
    }

    const rule = element.rule;
    let effect = "SHOW";

    if (rule["effect"]) {
      effect = rule["effect"] as string;
    }

    if (rule["condition"]) {
      const c = rule["condition"] as Record<string, any>;

      let scope = "";
      const enumValues: string[] = [];

      if (c["scope"]) {
        scope = c["scope"] as string;
      }

      if (c["schema"]) {
        const s = c["schema"];

        if (s["enum"]) {
          const enums = s["enum"] as string[];
          enumValues.push(...enums);
        }
      }

      const res = await this.getValueForProperty(scope);

      if (res !== null) {
        if (enumValues.includes(res)) {
          if (effect === "SHOW") return true;
          if (effect === "HIDE") return false;
        }
      }
    }

    if (effect === "SHOW") return false;
    if (effect === "HIDE") return true;

    return false;
  }

  async getValueForProperty(scope: string): Promise<string | null> {
    const element = await this.getElementForScope(scope);

    if (element !== null) {
      if (element.options !== null) {
        if (this.currentValues !== null) {
          const currentValue = this.currentValues.find(
            (b) => b.referential_path === element.options.full_referential_path
          );

          if (currentValue !== undefined) {
            return currentValue.value;
          }
        }
      }

      if (element.currentValue) {
        return element.currentValue.toString();
      } else if (element.defaultValue) {
        return element.defaultValue.toString();
      }
    }

    return null;
  }

  async insertCurrentValues(valueList: CurrentValueModel[]): Promise<void> {
    if (this.formTemplateData !== null) {
      for (const item of valueList) {
        const element = await this.elementForReferentialPath(
          item.referential_path,
          this.formTemplateData.ui_schema.elements
        );

        if (element !== null) {
          item.label = element.label;
        }

        const curr = await this.elementForReferentialPath(
          item.referential_path,
          this.formTemplateData.ui_schema.elements
        );

        if (curr !== null) {
          curr.currentValue = item.value;
        }
      }
    }
  }

  async getCategories(): Promise<FormElementDisplay[] | null> {
    const filteredCats: FormElementDisplay[] = [];

    if (this.formTemplateData !== null) {
      const schema = this.formTemplateData.ui_schema;
      const categorization = schema.elements.find(
        (b) => b.type === "Categorization"
      );

      if (categorization !== undefined) {
        const categories = categorization.elements;

        for (const category of categories) {
          const show = await this.checkShowRuleCategoryCondition(category);
          if (show) {
            filteredCats.push(category);
          }
        }

        this.cats = filteredCats;

        return filteredCats;
      }
    }

    return null;
  }

  async processFormTemplate() {
    if (this.formTemplateData !== null) {
      await this.processTemplateData(this.formTemplateData);
      await this.processVariables();
    }
  }

  async readFromFile(filename: string): Promise<string> {
    const docPath = ""; // Set the document path as needed

    return docPath;
  }

  async processVariables() {
    this.formVariableElements = [];

    if (this.formTemplateData !== null) {
      for (const element of this.formTemplateData.ui_schema.elements) {
        await this.processVariablesElement(element);
      }

      for (const element of this.formVariableElements) {
        await this.processConditionals(element);
      }
    }
  }

  async processConditionals(element: FormElementDisplay) {
    if (element.options && element.options.conditionals) {
      const conditionals = element.options.conditionals;

      for (const conditional of conditionals) {
        const { comparator, depends_on, set_variable, value_to_compare } =
          conditional;
        const res = await this.getValueForProperty(depends_on);

        if (res !== null) {
          if (comparator !== null) {
            if (comparator === "=") {
              if (res === value_to_compare) {
                element.currentValue = set_variable;
              }
            } else if (comparator === "!=") {
              if (res !== value_to_compare) {
                element.currentValue = set_variable;
              }
            }
          } else {
            if (res === value_to_compare) {
              element.currentValue = set_variable;
            }
          }
        }
      }
    }

    if (element.options && element.options.calculation) {
      const calculation = element.options.calculation;
      const variablesList: string[] = [];

      if (calculation.includes("$")) {
        let tempCalc = calculation;

        const pattern = /\$\w+/g;
        const matches = tempCalc.match(pattern);

        if (matches) {
          variablesList.push(...matches);
        }

        for (const variable of variablesList) {
          const property = variable.replace("$", "");
          const res = await this.getVariableValueForProperty(property);

          if (res !== "") {
            tempCalc = tempCalc.replace(variable, res!);
          }
        }

        try {
          const result = eval(tempCalc);

          element.currentValue = result.toString();
          // console.log(result.toString());
        } catch (ex) {
          element.currentValue = null;
        }
      }
    }
  }

  extractVariable(str: string): string {
    const pattern = /\/(\w+)\//;
    const match = str.match(pattern);

    if (match) {
      return match[1];
    }

    return "";
  }

  async getVariableValueForProperty(scope: string): Promise<string | null> {
    return await this.getValueForProperty(scope);
  }

  async processVariablesElement(element: FormElementDisplay) {
    if (element.options && element.options.hidden !== null) {
      if (element.options.hidden) {
        this.formVariableElements.push(element);
      }
    }

    if (element.elements) {
      for (const elem of element.elements) {
        await this.processVariablesElement(elem);
      }
    }
  }

  getAnswerValueUsingScope(scope: string): string | null {
    if (!scope) {
      return null;
    }

    if (this.answerValues) {
      const existing = this.answerValues.find((b) => b.Value.scope === scope);

      if (existing && existing.Value) {
        return existing.Value.value;
      }
    }

    return null;
  }

  async processTemplateData(templateData: FormTemplateData) {
    this.formElements = [];

    for (const element of templateData.ui_schema.elements) {
      if (element.type && element.type === "Categorization") {
        await this.processElement(element, null);
      }
    }
  }

  getEntryType(
    type: string,
    property: FormProperty,
    element: FormElementDisplay
  ): FormEntryType {
    let entryType: FormEntryType = FormEntryType.TextEntry;

    if (type === "VerticalLayout") {
      entryType = FormEntryType.VerticalLayoutEntry;
    } else if (type === "HorizontalLayout") {
      entryType = FormEntryType.HorizontalLayoutEntry;
    } else if (type === "Label") {
      entryType = FormEntryType.LabelEntry;
    } else if (type === "Category") {
      entryType = FormEntryType.CategoryEntry;
    } else if (type === "Categorization") {
      entryType = FormEntryType.CategorizationEntry;
    } else if (type === "Control") {
      entryType = FormEntryType.TextEntry;
      if (property !== undefined) {
        if (property && property.type === "boolean") {
          entryType = FormEntryType.Checkbox;
        }
        if (property && property.format === "date") {
          entryType = FormEntryType.DateEntry;
        }
        if (element.options && element.options.multi) {
          entryType = FormEntryType.TextEditorEntry;
        }
      }
    } else {
      console.log("To Be Added");
    }

    return entryType;
  }

  async processRule(element: FormElementDisplay) {
    if (!element.rule) {
      return;
    }

    const rule = element.rule;
    let effect = "SHOW";

    if (rule.effect) {
      effect = rule.effect;
    }
    if (effect === "HIDE") {
      // console.log('');
    }

    if (rule.condition) {
      const c = rule.condition;

      if (c.scope) {
        element.DependScope = c.scope;
      }

      if (c.conditions) {
        const opType = c.type;
        const s = c.conditions;
        const cList = s;

        const results: boolean[] = [];

        for (const condition of cList) {
          const result = await this.checkRule(condition, effect);
          results.push(result);
        }

        let returnValue = results[0];

        for (const result of results) {
          returnValue = returnValue && result;
        }

        return;
      } else {
        const res = await this.getRuleInvolved(c);

        element.DependValues = res;
      }
    }

    element.DependAction = effect;
  }

  async getRuleInvolved(c: any): Promise<string[]> {
    const enumValues: string[] = [];
    const scope = c.scope;

    if (c.schema) {
      const s = c.schema;

      if (s.length > 0) {
        const first = s[0];

        for (const key in first) {
          if (first.hasOwnProperty(key)) {
            const val = first[key];

            try {
              const valStr = val.toString();
              enumValues.push(valStr);
            } catch (ex) {
              console.error((ex as Error).message);
            }
          }
        }
      }
    }

    return enumValues;
  }

  async processElement(
    element: FormElementDisplay,
    formElementDisplay: FormElementDisplay | null
  ) {
    let label = "";
    let text = "";
    let type = "";
    let scope = "";
    let entryType: FormEntryType = FormEntryType.LabelEntry;

    let rule: Dictionary<any> | null = null;
    let options: ElementOptions | null = null;
    let elements: FormElementDisplay[] | null = null;
    let enums: string[] | null = null;

    label = element.label;
    text = element.text;
    type = element.type;
    scope = element.scope;
    rule = element.rule;
    options = element.options;
    elements = element.elements;

    if (rule) {
      await this.processRule(element);
      // console.log('rule');
    }

    if (element.options) {
      if (element.options.referential_path === "occupiers") {
        // console.log('elements');
      }
    }
    if (element.scope) {
      const scopeStripped = element.scope.replace("#/properties/", "");
      if (scopeStripped !== element.label) {
        try {
          element.options.full_referential_path = atob(scopeStripped);
        } catch (error) {
          let er = error as Error;
          element.options.full_referential_path = scopeStripped;
        }
      } else {
        element.options.full_referential_path = element.scope.replace(
          "#/properties/",
          ""
        );
      }

      if (element.options.answer_type === "Email") {
        element.is_email = true;
      }
      if (element.options.answer_type === "Phone Number") {
        element.is_phone_number = true;
      }

      if (element.scope.includes("#/properties/")) {
        const key = scope.replace("#/properties/", "");

        let properties = this.formTemplateData!.schema
          .properties as Dictionary<FormProperty>;
        const property = properties[key];

        const required = this.required.includes(key);

        let decodedProperty = key;

        if (key !== element.label) {
          try {
            decodedProperty = atob(key);
          } catch (ex) {
            // console.error((ex as Error).message);
          }
        }

        let formTemplateData = this.formTemplateData!
          .data as Dictionary<string>;
        const defaultValue = formTemplateData[key];
        // const defaultValue = this.formTemplateVersion!.data.find((b: { Key: string; }) => b.Key === key).Value;

        entryType = this.getEntryType(type, property, element);

        if (property) {
          if (property.enum) {
            const enumValues = property.enum;
            enums = enumValues;
            entryType = FormEntryType.ComboBoxEntry;
            if (element.options) {
              if (
                element.options.multiselect === true &&
                element.options.answer_type === "Dropdown Select Multiple"
              ) {
                entryType = FormEntryType.MultiSelectEntry;
              }
            }
          }

          if (property.format) {
            if (property.format === "date") {
              entryType = FormEntryType.DateEntry;
            }
          }
        }

        element.defaultValue = defaultValue;
        element.required = required;

        const currentValue = await this.getAnswerValue(
          element.options.full_referential_path
        );

        if (currentValue) {
          element.currentValue = currentValue;
        }
      }
    } else {
      if (type === "VerticalLayout") {
        entryType = FormEntryType.VerticalLayoutEntry;
      } else if (type === "HorizontalLayout") {
        entryType = FormEntryType.HorizontalLayoutEntry;
      } else if (type === "Label") {
        entryType = FormEntryType.LabelEntry;
      } else if (type === "Category") {
        entryType = FormEntryType.CategoryEntry;
      } else if (type === "Categorization") {
        entryType = FormEntryType.CategorizationEntry;
      } else if (type === "Control") {
        entryType = FormEntryType.TextEntry;
        if (element.options) {
          if (element.options.multi === true) {
            entryType = FormEntryType.TextEditorEntry;
          }
        }
      } else {
        console.log("To Be Added");
      }
    }

    element.entryType = entryType;
    element.enums = enums!;
    if (element.label === "occupier details") {
      console.log("occupier details");
    }

    if (element.options) {
      if (element.options.is_array === true) {
        var rootName = element.options.referential_path;
        // New array of elements
        element.entryType = FormEntryType.AddItemsEntry;
        if (element.options.detail.elements) {
          element.addItems = {} as AddItemsDisplayModel;
          element.addItems.Items = [];

          const addItems: AddItemDisplayModel[] =
            new Array<AddItemDisplayModel>(Constants.maxAddItems);

          for (let i = 0; i < Constants.maxAddItems; i++) {
            // const itemDetails = new AddItemDisplayModel();
            var itemDetails: AddItemDisplayModel = {
              ItemDetails: [],
            };

            itemDetails.ItemDetails = [];

            for (const item of element.options.detail.elements) {
              if (item.rule) {
                await this.processRule(item);
                // console.log('rule');
              }

              const itemElement = item as FormElementDisplay;
              let subEnums: string[] | null = null;

              if (itemElement.scope.includes("#/properties/")) {
                const key = itemElement.scope.replace("#/properties/", "");
                const propKey = element.scope.replace("#/properties/", "");

                if (itemElement.options.answer_type === "Dropdown") {
                  let properties = this.formTemplateData!.schema
                    .properties as Dictionary<FormProperty>;
                  const mainProperty = properties[propKey];

                  const mainPropertyItems =
                    mainProperty.items as Dictionary<FormProperty>;
                  // const mainProperty = this.formTemplateVersion!.schema.properties.find((b: { Key: string; }) => b.Key === propKey);
                  if (mainPropertyItems) {
                    const propertyItems =
                      mainPropertyItems.properties as Dictionary<any>;

                    const props = propertyItems[key] as Dictionary<any>;

                    const propEnum = props["enum"] as any;

                    const propEnums = propEnum as string[];

                    subEnums = [];

                    propEnums?.forEach((enumValue) => {
                      const enumValueStr = enumValue as string;
                      subEnums!.push(enumValueStr);
                    });
                  }
                }

                let properties = this.formTemplateData!.schema
                  .properties as Dictionary<FormProperty>;
                var subProperty = properties[key];

                let data = this.formTemplateData!.data as Dictionary<string>;

                const subDefaultValue = data[key];

                itemElement.defaultValue = subDefaultValue;

                entryType = this.getEntryType(
                  itemElement.type,
                  subProperty,
                  element
                );

                if (item.options) {
                  if (item.options.answer_type) {
                    if (item.options.answer_type === "Date") {
                      entryType = FormEntryType.DateEntry;
                    }
                    if (item.options.answer_type === "Phone Number") {
                      itemElement.is_phone_number = true;
                    }
                    if (item.options.answer_type === "Email") {
                      itemElement.is_email = true;
                    }
                  }
                }

                if (subEnums) {
                  entryType = FormEntryType.ComboBoxEntry;
                  if (element.options) {
                    if (
                      element.options.multiselect === true &&
                      element.options.answer_type === "Dropdown Select Multiple"
                    ) {
                      entryType = FormEntryType.MultiSelectEntry;
                    }
                  }
                }
                if (subProperty) {
                  if (subProperty.enum) {
                    const enumValues = subProperty.enum;
                    subEnums = enumValues;
                    entryType = FormEntryType.ComboBoxEntry;
                    if (element.options) {
                      if (
                        element.options.multiselect === true &&
                        element.options.answer_type ===
                          "Dropdown Select Multiple"
                      ) {
                        entryType = FormEntryType.MultiSelectEntry;
                      }
                    }
                  }

                  if (subProperty.format) {
                    if (subProperty.format === "date") {
                      entryType = FormEntryType.DateEntry;
                    }
                  }
                }

                itemElement.entryType = entryType;
                itemElement.enums = subEnums!;

                itemElement.options.referential_path_index = i;

                itemElement.options.full_referential_path = `${rootName}[${i}].${itemElement.options.referential_path}`;

                itemDetails.ItemDetails.push(itemElement);
              }
            }

            element.addItems.Items.push(
              JSON.parse(JSON.stringify(itemDetails))
            );
            element.numAddItems = await this.getNumberAddItems(element);
          }
        }
      }
    }

    const showSubElements = await this.checkShowRuleCondition(element);

    if (elements) {
      for (const elem of elements) {
        await this.processElement(elem, formElementDisplay);
      }
    }
  }

  async getNumberAddItems(formElement: FormElementDisplay): Promise<number> {
    let numItems = 0;

    for (const addItem of formElement.addItems!.Items) {
      if (addItem.ItemDetails[0].currentValue != null) {
        if (addItem.ItemDetails[0].currentValue.toString().length > 0) {
          numItems++;
        }
      }
    }

    return numItems;
  }

  async getAnswerValue(
    scope: string | null
  ): Promise<NovexAnswerObject | null> {
    if (scope == null) {
      return null;
    }
    // var answerValue = await CoreData.Instance().GetAnswerValue(scope);

    if (this.answerValues != null) {
      if (this.answerValues.hasOwnProperty(scope)) {
        // return this.answerValues[scope].Value;
        const answerValue = this.answerValues.find(
          (b) => b.Value.scope === scope
        );
        if (answerValue) {
          return answerValue.Value;
        }
        // return this.answerValues!.find((b) => b.Value.scope === scope);
      }
      // var answerFromDefaults = await getValueFromDefaults(scope);

      // return answerFromDefaults;
    }

    return null;
  }

  async elementForReferentialPath(
    refPath: string,
    formElements: FormElementDisplay[]
  ): Promise<FormElementDisplay | null> {
    for (const element of formElements) {
      if (
        element.options &&
        element.options.full_referential_path === refPath
      ) {
        return element;
      }

      if (element.addItems) {
        for (const subElements of element.addItems.Items) {
          if (subElements.ItemDetails) {
            for (const subId of subElements.ItemDetails) {
              if (subId.options.full_referential_path === refPath) {
                return subId;
              }

              if (subId.elements) {
                const subSubElement = await this.elementForReferentialPath(
                  refPath,
                  subId.elements
                );
                if (subSubElement) {
                  return subSubElement;
                }
              }
            }
          }
        }
      }

      if (element.elements) {
        for (const subElements of element.elements) {
          if (
            subElements.options &&
            subElements.options.full_referential_path === refPath
          ) {
            return subElements;
          }

          if (subElements.elements) {
            const subSubElement = await this.elementForReferentialPath(
              refPath,
              subElements.elements
            );
            if (subSubElement) {
              return subSubElement;
            }
          }
        }
      }
    }

    return null;
  }

  async setElementForReferentialPath(
    refPath: string,
    value: string,
    formElements: FormElementDisplay[]
  ): Promise<FormElementDisplay | null> {
    for (const element of formElements) {
      if (
        element.options &&
        element.options.full_referential_path === refPath
      ) {
        element.currentValue = value;
        return element;
      }

      if (element.addItems) {
        for (const subElements of element.addItems.Items) {
          if (subElements.ItemDetails) {
            for (const subId of subElements.ItemDetails) {
              if (
                subId.options &&
                subId.options.full_referential_path === refPath
              ) {
                subId.currentValue = value;
                return subId;
              }

              if (subId.elements) {
                const subSubElement = await this.setElementForReferentialPath(
                  refPath,
                  value,
                  subId.elements
                );
                if (subSubElement) {
                  subSubElement.currentValue = value;
                  return subSubElement;
                }
              }
            }
          }
        }
      }

      if (element.elements) {
        for (const subElements of element.elements) {
          if (
            subElements.options &&
            subElements.options.full_referential_path === refPath
          ) {
            subElements.currentValue = value;
            return subElements;
          }

          if (subElements.elements) {
            const subSubElement = await this.setElementForReferentialPath(
              refPath,
              value,
              subElements.elements
            );
            if (subSubElement) {
              subSubElement.currentValue = value;
              return subSubElement;
            }
          }
        }
      }
    }

    return null;
  }

  async getElementForScope(scope: string): Promise<FormElementDisplay | null> {
    const formElements = this.formTemplateData!.ui_schema.elements;
    return await this.GetElementForScope(scope, formElements);
  }

  async GetElementForScope(
    scope: string,
    formElements: FormElementDisplay[]
  ): Promise<FormElementDisplay | null> {
    for (const element of formElements) {
      if (element.scope && element.scope.includes(scope)) {
        return element;
      }

      if (element.addItems) {
        for (const subElements of element.addItems.Items) {
          if (subElements.ItemDetails) {
            for (const subId of subElements.ItemDetails) {
              if (subId.scope && subId.scope.includes(scope)) {
                return subId;
              }

              if (subId.elements) {
                const subSubElement = await this.GetElementForScope(
                  scope,
                  subId.elements
                );
                if (subSubElement) {
                  return subSubElement;
                }
              }
            }
          }
        }
      }

      if (element.elements) {
        for (const subElements of element.elements) {
          if (subElements.scope && subElements.scope.includes(scope)) {
            return subElements;
          }

          if (subElements.elements) {
            const subSubElement = await this.GetElementForScope(
              scope,
              subElements.elements
            );
            if (subSubElement) {
              return subSubElement;
            }
          }
        }
      }
    }

    return null;
  }

  async setAnswerValue(referentialPath: string, value: string): Promise<void> {
    const curr = await this.setElementForReferentialPath(
      referentialPath,
      value,
      this.formTemplateData!.ui_schema.elements
    );

    if (curr) {
      curr.currentValue = value;
    }
  }

  async getAddItemsElementForRefPath(
    refPath: string,
    formElements: FormElementDisplay[]
  ): Promise<FormElementDisplay | null> {
    return await this.elementForReferentialPath(refPath, formElements);
  }

  async setCurrentValues(valueList: CurrentValueModel[]): Promise<void> {
    // Assuming you have a property named 'CurrentValues' in your class
    // You can directly set the value like this:
    this.currentValues = valueList;
  }
}

export default FormTemplateService;
