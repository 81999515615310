import { Typography } from "@mui/material";
import { Box, Theme, useTheme } from "@mui/system";
import React from "react";
import data from "../../Config/process.json";
import bridgingdata from "../../Config/bridging-process.json";

const styles = (theme: Theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "75%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    borderColor: "#E6EBED",
  },
});

export default function Process({
  caseStatus,
  bridging,
}: {
  caseStatus: number;
  bridging?: boolean;
}) {
  const classes = styles(useTheme());

  const processData = bridging ? bridgingdata : data;

  return (
    <Box bgcolor="#E6EBED">
      <Box padding={2}>
        <Box
          sx={{ borderColor: "#E6EBED" }}
          borderBottom={1}
          bgcolor="white"
          textAlign="left"
        >
          <Box padding={3}>
            <Typography fontWeight={"bold"} fontSize={14}>
              Process: Your {bridging ? "Bridging Loan " : "Equity Release "}
              Journey
            </Typography>
            <Typography color="#8199A6" marginTop={2}>
              Our 10 Simple Steps to{" "}
              {bridging ? "Bridging Loans" : "Equity Release"}
            </Typography>
          </Box>
        </Box>
        <Box bgcolor="white" textAlign="left">
          <Box padding={3}>
            {processData.map((item: any, index: number) => (
              <Box marginTop={2} key={index}>
                <Box display="inline-flex">
                  <Box
                    borderRadius={"50%"}
                    marginRight={2}
                    marginBottom={1}
                    height={33}
                    width={33}
                    color="white"
                    bgcolor={item.id === caseStatus ? "#04334E" : "#8199A6"}
                    textAlign="center"
                    sx={{ verticalAlign: "middle", lineHeight: "33px" }}
                  >
                    {item.id}
                  </Box>
                  <Typography
                    color={item.id === caseStatus ? "#0DBC29" : ""}
                    textAlign={"left"}
                    fontWeight="bold"
                  >
                    {" "}
                    {item.title} {item.id === caseStatus && "- YOU ARE HERE"}
                  </Typography>
                </Box>
                <Box
                  paddingLeft={6}
                  paddingBottom={2}
                  marginTop={1}
                  borderBottom={1}
                  sx={classes.container}
                >
                  <Typography marginTop={2} color="#8199A6">
                    {item.content}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
