import React, { useEffect, useState } from "react";

import MenuBarItem from "./MenuBarItem/component";
import { svgimages } from "../../../utils/svgimages";
import { NavItem } from "../../../types/navItem";
import { Box, MenuList } from "@mui/material";
import { styled } from "@mui/material/styles";
import Updates from "../../../types/updates";
import { Theme, useTheme } from "@mui/system";

const Root = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const styles = (theme: Theme) => ({
  leftBar: {
    marginTop: "25vh",
  },
});

export function MenuBar({
  menuClickHandler,
  updates,
}: {
  menuClickHandler: any;
  updates: Updates[];
}) {
  const [selected, setSelected] = useState<number>(0);

  const classes = styles(useTheme());

  const data: NavItem[] = [
    { id: 0, name: "Home", icon: "home" },
    { id: 1, name: "Process", icon: "transaction" },
    { id: 2, name: "FAQ", icon: "question" },
    {
      id: 3,
      name: "Updates",
      icon: "notification",
      alert: updates?.filter((u) => !u.seen)?.length > 0,
    },
  ];
  const profileData: NavItem[] = [
    { id: 4, name: "About", icon: "information" },
    { id: 6, name: "Logout", icon: "power" },
  ];

  useEffect(() => {
    setSelected(0);
  }, []);

  const itemSelectedHandler = (item: NavItem) => {
    setSelected(item.id);
    if (item.id === 0) {
      let selectedItem = data.find((item) => item.id === selected);
      menuClickHandler(selectedItem);
    }
  };

  useEffect(() => {
    if (selected < 4) {
      let selectedItem = data.find((item) => item.id === selected);
      menuClickHandler(selectedItem);
    } else {
      let selectedItem = profileData.find((item) => item.id === selected);
      menuClickHandler(selectedItem);
    }
    // eslint-disable-next-line
  }, [selected]);

  return (
    <Root>
      <Box
        position="fixed"
        width="16.67vw"
        textAlign={"left"}
        height="100vh"
        borderRight={1}
        sx={{ borderColor: "#E6EBED" }}
      >
        <Box textAlign={"center"} paddingTop={3}>
          <img src={svgimages["equilaw-logo"]} height="60" alt="equilaw" />
        </Box>
        <Box marginTop={4}>
          <MenuList>
            {data.map((item, index) => (
              <MenuBarItem
                key={index}
                menuBarItem={item}
                itemSelectedHandler={itemSelectedHandler}
                isSelected={item.id === selected}
              />
            ))}
          </MenuList>
        </Box>
        <Box sx={classes.leftBar}>
          <MenuList>
            {profileData.map((item, index) => (
              <MenuBarItem
                key={index}
                menuBarItem={item}
                itemSelectedHandler={itemSelectedHandler}
                isSelected={item.id === selected}
              />
            ))}
          </MenuList>
        </Box>
      </Box>
    </Root>
  );
}
