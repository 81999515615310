import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import React from "react";
import { NavItem } from "../../types/navItem";
import { svgimages } from "../../utils/svgimages";
import { Theme, useTheme } from "@mui/system";

const styles = (theme: Theme) => ({
  root: {
    bottom: 0,
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    minWidth: "100vw",
    maxWidth: "100vw",
    borderTop: 1,
  },
});

const BottomNav = ({
  menuClickHandler,
}: {
  menuClickHandler: any;
}): JSX.Element => {
  const classes = styles(useTheme());

  const data: NavItem[] = [
    { id: 0, name: "Home", icon: "home" },
    { id: 1, name: "Process", icon: "transaction" },
    { id: 2, name: "FAQ", icon: "question" },
    { id: 3, name: "Updates", icon: "notification" },
    { id: 6, name: "Logout", icon: "power" },
  ];

  return (
    <BottomNavigation sx={classes.root} showLabels>
      {data.map((item, index) => (
        <BottomNavigationAction
          key={index}
          onClick={() => menuClickHandler(item)}
          label={item.name}
          icon={
            <img
              alt="selected-item"
              src={svgimages[item.icon]}
              className="selecteditem.icon"
            />
          }
        />
      ))}
    </BottomNavigation>
  );
};

export default BottomNav;
