import axios, { AxiosResponse } from "axios";
import Cookies from "universal-cookie";

const docApiUrl =
  process.env.REACT_APP_DOC_API_URL || "https://doc-api-uat.novex.software";

export const downloadFile = async (
  filename: string,
  content: string,
  matterID: number
): Promise<void> => {
  const contentType = getFileContentType(content);
  if (content.includes(".msg")) {
    filename = filename.replaceAll(".html", ".msg").replaceAll(".txt", ".msg");
    if (!filename.includes(".")) {
      filename = filename + ".msg";
    }
  }

  if (content.includes(".eml")) {
    if (!filename.includes(".")) {
      filename = filename + ".eml";
    }
  }

  if (contentType === "text/html") {
    const { document_url } = await convertHTMLToPDF(content, matterID, true);
    content = document_url;
  }

  if (content.includes("novex-documents")) {
    try {
      if (content.includes(".docx")) {
        content = await getFileContent(content, true);
        const b64 = (await blobToBase64(content)) as any;
        content = await convertDOCXToPDF(
          b64.replaceAll(
            "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
            ""
          )
        );
        content = base64toBlob(content, "application/pdf") as any;
        filename = filename.replaceAll(".docx", ".pdf");
      } else {
        content = await getFileContent(content, true);
      }

      if (!filename.includes(".")) {
        filename = filename + ".pdf";
      }

      filename = filename.replaceAll(".html", ".pdf");

      const url = window.URL.createObjectURL(new Blob([content]));
      const anchorTag = document.createElement("a");
      anchorTag.setAttribute("target", "_blank");
      anchorTag.setAttribute("href", url);
      anchorTag.setAttribute("download", filename);

      anchorTag.style.display = "none";
      document.body.appendChild(anchorTag);
      anchorTag.click();
      document.body.removeChild(anchorTag);
    } catch (err) {
      console.log(err);
    }
  }
};

export const getFileContentType = (fileName: string): string => {
  const arr: string[] = fileName.split(".");
  const fileExtension: string = arr[arr.length - 1];
  switch (fileExtension.toLowerCase()) {
    case "doc":
      return "application/msword";
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "xls":
      return "application/vnd.ms-excel";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "ppt":
      return "application/vnd.ms-powerpoint";
    case "pptx":
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    case "odt":
      return "application/vnd.oasis.opendocument.text";
    case "jpg":
      return "image/jpeg";
    case "jpeg":
    case "png":
    case "gif":
      return `image/${fileExtension}`;
    case "eml":
      return "message/rfc822";
    case "msg":
      return "application/vnd.ms-outlook";
    case "html":
      return "text/html";
    case "txt":
      return "text/plain";
    case "pdf":
      return "application/pdf";
    default:
      return "text/html";
  }
};

const convertHTMLToPDF = async (
  htmlFile: string,
  matterID: number,
  store: boolean,
  fileContent?: string
): Promise<any> => {
  const params = htmlFile.split("/");
  let name = params[params.length - 1];
  name = name.replaceAll(".html", ".pdf");

  if (!fileContent) {
    fileContent = await getFileContent(htmlFile);
  }

  try {
    if (fileContent && !fileContent.includes("<html>"))
      fileContent = atob(fileContent);
  } catch (err) {}

  try {
    return axios({
      method: "post",
      url: `${docApiUrl}/html-convert/${matterID}?name=${name}&store=${store}&direct=true`,
      headers: {
        Authorization: `Bearer ${new Cookies().get("token")}`,
        "Content-Type": "text/html",
        Accept: "application/pdf",
      },
      data: fileContent,
    }).then((response: AxiosResponse) => {
      return response.data;
    });
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getFileContent = async (
  fileURL: string,
  blob?: boolean
): Promise<any> => {
  try {
    if (blob) {
      return axios({
        headers: {
          "X-Novex-Auth": `Bearer ${new Cookies().get("token")}`,
        },
        method: "get",
        url: fileURL,
        responseType: "blob",
      }).then((response: AxiosResponse) => {
        return response.data;
      });
    } else {
      return axios({
        headers: {
          "X-Novex-Auth": `Bearer ${new Cookies().get("token")}`,
        },
        method: "get",
        url: fileURL,
      }).then((response: AxiosResponse) => {
        return response.data;
      });
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

function base64toBlob(base64Data: any, contentType: string) {
  contentType = contentType || "";
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

const blobToBase64 = (blob: any) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result);
    };
  });
};

const convertDOCXToPDF = async (docXContent: string): Promise<any> => {
  try {
    return axios({
      method: "post",
      url: `${docApiUrl}/docx-convert`,
      headers: {
        Authorization: `Bearer ${new Cookies().get("token")}`,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      },
      data: docXContent,
    }).then((response: AxiosResponse) => {
      return response.data;
    });
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default downloadFile;
