import axios from "axios";

const identityUrl =
  process.env.REACT_APP_IDENTITY_URL || "https://identity-uat.novex.software";

const resetPassword = async (email: string): Promise<any> => {
  console.log("resetting password");

  try {
    const response = await axios.post(`${identityUrl}/update-password`, {
      email: email,
      forgotten_password: true,
    });
    console.log(response);
  } catch (error) {
    return error;
  }
};

export default resetPassword;
