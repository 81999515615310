import * as React from "react";
import { FormItem } from "../../../../types/formTemplate";
import { Grid, Typography, TextField } from "@mui/material";

import { UserData } from "../../../../types/user";
import updateReferentialPaths from "../../../../utils/updateReferentialPaths";

export default function TextView({
  setDisableSubmit,
  item,
  handleSaved,
  losingFocus,
  showLabel,
}: {
  setDisableSubmit?: (value: boolean) => void;
  item: FormItem;
  handleSaved: any;
  losingFocus: boolean;
  showLabel: boolean;
}) {
  const [answerValue, setAnswerValue] = React.useState<string>("");
  const [invalidPhoneNumber, setInvalidPhoneNumber] =
    React.useState<boolean>(false);
  const [invalidEmail, setInvalidEmail] = React.useState<boolean>(false);

  React.useEffect(() => {
    let answerValue = item.AnswerValue;
    if (!answerValue) {
      answerValue = "";
    }
    setAnswerValue(answerValue);
  }, [item]);

  const saveAnswer = async () => {
    console.log("saving answer", item, answerValue);

    if (answerValue === item.AnswerValue) {
      handleSaved(item);
    } else {
      const userDetails = JSON.parse(
        localStorage.getItem("user") || "{}"
      ) as UserData;
      console.log("saving answer");
      await updateReferentialPaths(
        userDetails.matter_id,
        item.FullReferentialPath,
        answerValue
      );
      console.log("saved answer");
      let newItem = item;
      newItem.AnswerValue = answerValue;
      handleSaved(newItem);
    }
  };

  React.useEffect(() => {
    if (losingFocus) {
      saveAnswer();
    }
  }, [losingFocus]);

  function isValidPhoneNumber(inputString: string): boolean {
    // Define a regular expression pattern for the allowed characters
    const pattern = /^[+\d\s]+$/;

    // Test the input string against the pattern
    return pattern.test(inputString);
  }

  function isValidEmail(email: string): boolean {
    // Define a regular expression pattern for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the pattern
    return emailRegex.test(email);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (item.IsPhoneNumber && !isValidPhoneNumber(event.target.value)) {
      setInvalidPhoneNumber(true);
      setDisableSubmit && setDisableSubmit(true);
    } else if (invalidPhoneNumber) {
      setDisableSubmit && setDisableSubmit(false);
      setInvalidPhoneNumber(false);
    }

    if (item.IsEmail && !isValidEmail(event.target.value)) {
      setInvalidEmail(true);
      setDisableSubmit && setDisableSubmit(true);
    } else if (invalidEmail) {
      setDisableSubmit && setDisableSubmit(false);
      setInvalidEmail(false);
    }

    setAnswerValue(event.target.value);
  };

  return (
    <Grid container>
      {showLabel ? (
        <Grid item xs={5}>
          <Typography paddingBottom={2} data-testid="formItemLabel">
            {item!.Label}
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={7}>
        <TextField
          error={invalidPhoneNumber || invalidEmail}
          helperText={
            invalidPhoneNumber
              ? "Phone number may only contain +, space characters or numeric values"
              : invalidEmail
              ? "Please enter a valid email address"
              : ""
          }
          data-testid="textview"
          inputProps={{ "data-testid": "textview-input" }}
          variant="outlined"
          value={answerValue}
          fullWidth
          name={item.FullReferentialPath}
          id={item.FullReferentialPath}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
}
