import axios from "axios";
import Cookies from "universal-cookie";

const clientApiUrl =
  process.env.REACT_APP_CLIENT_API_URL || "https://clients-uat.novex.software";

export default async (matterId: number) => {
  const apiURL = `${clientApiUrl}/matters/${matterId}/client-details`;

  const token = new Cookies().get("token");

  try {
    const response = await axios.get(apiURL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err) {
    console.error(err);
  }
};
