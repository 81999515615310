import { Close } from "@mui/icons-material";
import { Alert, Grid, Link, Modal, Snackbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import presignUpload from "../../../utils/presignUpload";
import upload from "../../../utils/uploadToS3";
import receiveChecklistItem from "../../../utils/receiveChecklistItem";
import { ChecklistItem } from "../../../types/checklist";
import getChecklist from "../../../services/ChecklistService";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UploadModal = ({
  open,
  setOpen,
  name,
  loadUpdates,
  setChecklistData,
  setSnackbarOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  name: string;
  loadUpdates: () => void;
  setChecklistData: (data: ChecklistItem[]) => void;
  setSnackbarOpen: (open: boolean) => void;
}): JSX.Element => {
  const handleFileUpload = async (files: File[]) => {
    try {
      for (const file of files) {
        const presignUploadResponse = await presignUpload(file.name, file.type);

        await upload(file, presignUploadResponse.presigned_url);

        await receiveChecklistItem(name, presignUploadResponse.document_url);
      }

      loadUpdates();

      const checklistData = await getChecklist();
      setChecklistData(checklistData);

      setOpen(false);
      setSnackbarOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    handleFileUpload(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ borderColor: "#E6EBED" }} borderBottom={1}>
            <Box padding={4}>
              <Grid container>
                <Grid item xs={11}></Grid>
                <Grid item xs={1}>
                  <Box
                    sx={{
                      color: "#04334E",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    display="inline-flex"
                    onClick={() => setOpen(false)}
                  >
                    <Link data-testid="close" component="button">
                      Close
                    </Link>
                    <Close sx={{ marginLeft: 1 }} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Typography fontSize={30}>{name}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box textAlign={"center"} paddingTop={4}>
            <Box
              {...getRootProps()}
              sx={{ borderStyle: "dashed", borderColor: "#E6EBED" }}
              border={2}
              padding={4}
            >
              <input data-testid="file-input" hidden {...getInputProps()} />
              <Typography color="#8199A6" fontWeight="bold">
                Drag your documents here or{" "}
                <Link color="#8199A6" sx={{ cursor: "pointer" }}>
                  browse files
                </Link>
                . You may upload multiple files at once.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UploadModal;
