import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid, InputLabel, Link, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import updateReferentialPaths from "../../../utils/updateReferentialPaths";
import { UserData } from "../../../types/user";
import { ClientDetails } from "../../../types/clientDetails";
import changePassword from "../../../utils/changePassword";
import PasswordStrengthBar from "react-password-strength-bar";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function EditModal({
  open,
  setOpen,
  displayName,
  name,
  value,
  clientDetails,
  setClientDetails,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  displayName: string;
  name: string;
  value: string;
  clientDetails: ClientDetails;
  setClientDetails: (clientDetails: ClientDetails) => void;
}) {
  const [modalValue, setModalValue] = React.useState<string>(value);
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [currentPassword, setCurrentPassword] = React.useState<string>("");

  React.useEffect(() => {
    setModalValue(value);
    setConfirmPassword("");
    setCurrentPassword("");
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "confirm-password") {
      setConfirmPassword(event.target.value);
    } else if (event.target.name === "current-password") {
      setCurrentPassword(event.target.value);
    } else {
      setModalValue(event.target.value);
    }
  };

  const handleSave = async () => {
    if (name === "password") {
      try {
        await changePassword(clientDetails.email, currentPassword, modalValue);
      } catch (err) {
        console.log(err);
      }
      setOpen(false);
      return;
    }

    let refPath,
      newValue = "";

    if (name === "notification_preference") {
      refPath = "clients[0].notification_preference";
      newValue =
        clientDetails.notification_preference === "mobile" ? "email" : "mobile";
    } else if (name === "full_name") {
      refPath = "clients[0].full_name";
      newValue = modalValue;
    } else if (name === "phone") {
      refPath = "clients[0].mobile";
      newValue = modalValue;
    } else {
      setOpen(false);
      return;
    }

    try {
      const userDetails = JSON.parse(
        localStorage.getItem("user") || "{}"
      ) as UserData;

      await updateReferentialPaths(userDetails.matter_id, refPath, newValue);

      setClientDetails({ ...clientDetails, [name]: newValue });

      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box sx={style}>
        <Box sx={{ borderColor: "#E6EBED" }} borderBottom={1}>
          <Box padding={4}>
            <Grid container>
              <Grid item xs={11}></Grid>
              <Grid item xs={1}>
                <Box
                  sx={{
                    color: "#04334E",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  display="inline-flex"
                  onClick={() => setOpen(false)}
                >
                  <Link data-testid="close" component="button">
                    Close
                  </Link>
                  <Close sx={{ marginLeft: 1 }} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography fontSize={30}>
                    {name === "notification_preference" ? (
                      <>
                        Are you sure you want to change your notification
                        preferences?
                      </>
                    ) : (
                      <>Edit {displayName}</>
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box padding={4}>
          {name === "password" ? (
            <>
              <InputLabel
                sx={{ textAlign: "left", marginBottom: 2 }}
                htmlFor={"current-password"}
              >
                Current password
              </InputLabel>
              <TextField
                onChange={handleChange}
                value={currentPassword}
                name={"current-password"}
                sx={{ width: "50%" }}
                type="password"
                id={"current-password"}
                variant="outlined"
              />
              <InputLabel
                sx={{ textAlign: "left", marginBottom: 2, marginTop: 3 }}
                htmlFor={"password"}
              >
                New password
              </InputLabel>
              <TextField
                onChange={handleChange}
                value={modalValue}
                name={"password"}
                sx={{ width: "50%" }}
                type="password"
                id={"password"}
                variant="outlined"
              />
              <PasswordStrengthBar
                minLength={10}
                style={{ width: "50%" }}
                password={modalValue}
              />
              <InputLabel
                sx={{ textAlign: "left", marginBottom: 2, marginTop: 3 }}
                htmlFor="confirm-password"
              >
                Confirm new password
              </InputLabel>
              <TextField
                onChange={handleChange}
                value={confirmPassword}
                name="confirm-password"
                sx={{ width: "50%" }}
                id="confirm-password"
                variant="outlined"
                type="password"
              />
            </>
          ) : name === "notification_preference" ? (
            <></>
          ) : (
            <>
              <InputLabel
                sx={{ textAlign: "left", marginBottom: 2 }}
                htmlFor={name}
              >
                {displayName}
              </InputLabel>
              <TextField
                onChange={handleChange}
                value={modalValue}
                name={name}
                sx={{ width: "50%" }}
                id={name}
                variant="outlined"
                data-testid={`edit-${name}`}
              />
            </>
          )}
        </Box>
        <Box paddingLeft={4} paddingBottom={4}>
          <Link
            onClick={() => {
              setOpen(false);
            }}
            component="button"
            color="rgba(222, 134, 70, 1);"
            data-testid="cancel"
          >
            Cancel
          </Link>
          <Button
            sx={{ marginLeft: 5, backgroundColor: "rgba(222, 134, 70, 1);" }}
            data-testid="save"
            variant="contained"
            onClick={handleSave}
            disabled={
              (name === "password" && modalValue !== confirmPassword) ||
              (name === "password" && modalValue === "")
            }
          >
            {name === "notification_preference" ? "Confirm" : "Save"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
