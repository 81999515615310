import axios from "axios";

const identityUrl =
  process.env.REACT_APP_IDENTITY_URL || "https://identity-uat.novex.software";

const changePassword = async (
  email: string,
  oldPassword: string,
  newPassword: string
): Promise<any> => {
  try {
    const response = await axios.post(`${identityUrl}/update-password`, {
      email: email,
      old_password: oldPassword,
      new_password: newPassword,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export default changePassword;
