import axios from "axios";

const upload = async (file: File, uploadUrl: string): Promise<any> => {
  const response = await axios.put(uploadUrl, await file.arrayBuffer(), {
    headers: { "Content-Type": file.type },
  });

  return response.data;
};

export const uploadHTML = async (
  html: string,
  uploadUrl: string
): Promise<any> => {
  const response = await axios.put(uploadUrl, html, {
    headers: { "Content-Type": "text/html" },
  });

  return response.data;
};

export default upload;
