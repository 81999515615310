import React, { useEffect, useState } from "react";
import { ChecklistItem } from "../../../types/checklist.d";
import { Box } from "@mui/system";
import { Button, Grid, Typography } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import UploadModal from "../UploadModal/component";
import { Download, Edit } from "@mui/icons-material";
import downloadFile from "../../../utils/downloadFile";
import { UserData } from "../../../types/user";
import receiveChecklistItem from "../../../utils/receiveChecklistItem";
import ReadAndSign from "../ReadAndSign/component";
import getChecklist from "../../../services/ChecklistService";

export default function OutstandingTaskItem({
  item,
  form_clickHandler,
  loadUpdates,
  setChecklistData,
  setReadonly,
  setSnackbarOpen,
}: {
  item: ChecklistItem;
  index: number;
  upload_clickHandler: any;
  form_clickHandler: any;
  read_clickHandler: any;
  loadUpdates: () => void;
  setChecklistData: (data: ChecklistItem[]) => void;
  setReadonly: (readonly: boolean) => void;
  setSnackbarOpen: (open: boolean) => void;
}) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [readAndSignOpen, setReadAndSignOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!readAndSignOpen) {
      loadUpdates();
    }
    // eslint-disable-next-line
  }, [readAndSignOpen]);

  const handleDownload = async () => {
    const userDetails = JSON.parse(
      localStorage.getItem("user") || "{}"
    ) as UserData;

    await downloadFile(
      item.case_history_item_name,
      item.case_history_item_url,
      userDetails.matter_id
    );

    await receiveChecklistItem(item.name, "");

    const checklistData = await getChecklist();
    setChecklistData(checklistData);
  };

  if (item.type === "document") {
    return [
      <Box
        key={item.name}
        sx={{ borderColor: "#E6EBED" }}
        borderBottom={1}
        padding={3}
      >
        <Grid rowSpacing={1} container>
          <Grid item xs={7}>
            <Typography fontWeight={"bold"}>{item.name}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontSize={10} color="#8199A6">
              Duration: 30 seconds
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography fontSize={12}>{item.upload_description}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Box textAlign={"right"}>
              <Button
                onClick={() => {
                  setModalOpen(true);
                }}
                variant="contained"
                sx={{ bgcolor: "#DE8646", textTransform: "none" }}
                startIcon={<UploadIcon />}
              >
                Upload
              </Button>
              <UploadModal
                setSnackbarOpen={setSnackbarOpen}
                setChecklistData={setChecklistData}
                loadUpdates={loadUpdates}
                name={item.name}
                open={modalOpen}
                setOpen={setModalOpen}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>,
    ];
  } else if (item.type === "form") {
    return [
      <Box
        key={item.name}
        sx={{ borderColor: "#E6EBED" }}
        borderBottom={1}
        padding={3}
      >
        <Grid rowSpacing={1} container>
          <Grid item xs={7}>
            <Typography fontWeight={"bold"}>{item.name}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontSize={10} color="#8199A6">
              Duration: 5 - 10 minutes
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography fontSize={12}>{item.upload_description}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Box textAlign={"right"}>
              <Button
                variant="contained"
                sx={{ bgcolor: "#DE8646", textTransform: "none" }}
                onClick={() => {
                  setReadonly(false);
                  form_clickHandler(
                    item.form_template_version_name &&
                      item.form_template_version_name !== ""
                      ? item.form_template_version_name
                      : "IQ2"
                  );
                }}
              >
                Start Task
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>,
    ];
  } else if (item.type === "readonly") {
    return [
      <Box
        key={item.name}
        sx={{ borderColor: "#E6EBED" }}
        borderBottom={1}
        padding={3}
      >
        <Grid rowSpacing={1} container>
          <Grid item xs={7}>
            <Typography fontWeight={"bold"}>{item.name}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontSize={10} color="#8199A6">
              Duration: 5 minutes
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography fontSize={12}>{item.upload_description}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Box textAlign={"right"}>
              <Button
                onClick={handleDownload}
                variant="contained"
                sx={{ bgcolor: "#DE8646", textTransform: "none" }}
                startIcon={<Download />}
              >
                Download and Read
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>,
    ];
  } else if (item.type === "sig-form") {
    return (
      <Box
        key={item.name}
        sx={{ borderColor: "#E6EBED" }}
        borderBottom={1}
        padding={3}
      >
        <Grid rowSpacing={1} container>
          <Grid item xs={7}>
            <Typography fontWeight={"bold"}>{item.name}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontSize={10} color="#8199A6">
              Duration: 5 minutes
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography fontSize={12}>{item.upload_description}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Box textAlign={"right"}>
              <Button
                onClick={() => {
                  setReadAndSignOpen(true);
                }}
                variant="contained"
                sx={{ bgcolor: "#DE8646", textTransform: "none" }}
                startIcon={<Edit />}
              >
                Read and Sign
              </Button>
            </Box>
          </Grid>
        </Grid>
        <ReadAndSign
          setChecklistData={setChecklistData}
          open={readAndSignOpen}
          setOpen={setReadAndSignOpen}
          item={item}
        />
      </Box>
    );
  } else {
    return <h2 key={item.name}>{item.name} Not Found</h2>;
  }
}
