import React, { useEffect, useState } from "react";
import { svgimages } from "../../../utils/svgimages";
import AccountService from "../../../services/AccountService";
import { Box } from "@mui/system";
import {
  Button,
  Grid,
  InputLabel,
  Link,
  Snackbar,
  TextField,
} from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Link as RouterLink } from "react-router-dom";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Login({
  login_Handler,
}: {
  login_Handler: (loggedIn: boolean) => void;
}) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        loginHandler();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line
  }, [email, password]);

  const loginHandler = async () => {
    let _accountService = AccountService;

    try {
      const token = await _accountService.login(email, password);
      if (token) {
        login_Handler(true);
      } else {
        setOpen(true);
        login_Handler(false);
      }
    } catch (error) {
      console.log(error);
      setOpen(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "email") {
      setEmail(event.target.value);
    } else if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  };

  return (
    <Grid container>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        <Box textAlign={"center"} marginTop={2}>
          <img
            width="100%"
            src={svgimages["equilaw-logo"]}
            alt="Equilawlogo1651"
          />
        </Box>
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        <Box marginTop={8}>
          <InputLabel
            sx={{ textAlign: "left", marginBottom: "2vh" }}
            htmlFor="email"
          >
            Email
          </InputLabel>
          <TextField
            onChange={handleChange}
            value={email}
            fullWidth
            id="email"
            name="email"
            variant="outlined"
          />
        </Box>
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        <Box marginTop={2}>
          <InputLabel
            sx={{ textAlign: "left", marginBottom: "2vh" }}
            htmlFor="password"
          >
            Password
          </InputLabel>
          <TextField
            onChange={handleChange}
            value={password}
            name="password"
            type="password"
            fullWidth
            id="password"
            variant="outlined"
          />
        </Box>
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        <Box marginTop={4} textAlign={"left"}>
          <Grid container>
            <Grid item xs={6}>
              <Button
                onClick={loginHandler}
                variant="contained"
                sx={{ bgcolor: "#DE8646" }}
              >
                Login
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Link
                fontSize={14}
                color="#DE8646"
                component={RouterLink}
                to="/forgotten-password"
              >
                Forgotten Password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
          Your username or password is incorrect, please try again or reset it
          by choosing "Forgotten Password?"
        </Alert>
      </Snackbar>
    </Grid>
  );
}
