import {
  Button,
  Grid,
  InputLabel,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { svgimages } from "../../../utils/svgimages";
import { Box } from "@mui/system";
import changePassword from "../../../utils/changePassword";
import { UserData } from "../../../types/user";
import PasswordStrengthBar from "react-password-strength-bar";

const ChangePassword = (): JSX.Element => {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [helpActive, setHelpActive] = useState<boolean>(false);

  const changePasswordHandler = async () => {
    setDisabled(true);
    const userDetails = JSON.parse(
      localStorage.getItem("user") || "{}"
    ) as UserData;

    await changePassword(userDetails.email, oldPassword, password);
    userDetails.temporary_password = false;
    localStorage.setItem("user", JSON.stringify(userDetails));
    setDisabled(false);
    window.location.href = "/";
  };

  const passwordReg =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "password") {
      setPassword(event.target.value);
    } else if (event.target.name === "confirmPassword") {
      setConfirmPassword(event.target.value);
    } else if (event.target.name === "old-password") {
      setOldPassword(event.target.value);
    }
  };

  return (
    <Grid container>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        <Box textAlign={"center"} marginTop={2}>
          <img
            width="100%"
            src={svgimages["equilaw-logo"]}
            alt="Equilawlogo1651"
          />
        </Box>
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        <Box marginTop={2}>
          <InputLabel
            sx={{ textAlign: "left", marginBottom: "2vh" }}
            htmlFor="old-password"
          >
            Temporary Password
          </InputLabel>
          <TextField
            inputProps={{ "data-testid": "old-password" }}
            onChange={handleChange}
            value={oldPassword}
            name="old-password"
            type="password"
            fullWidth
            id="old-password"
            variant="outlined"
          />
        </Box>
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        <Box marginTop={2}>
          <InputLabel
            sx={{ textAlign: "left", marginBottom: "2vh" }}
            htmlFor="password"
          >
            New Password
          </InputLabel>
          <TextField
            inputProps={{ "data-testid": "password" }}
            onChange={handleChange}
            value={password}
            name="password"
            type="password"
            fullWidth
            id="password"
            variant="outlined"
            onFocus={() => {
              setHelpActive(true);
            }}
            onBlur={() => {
              setHelpActive(false);
            }}
          />
          <PasswordStrengthBar minLength={10} password={password} />
        </Box>
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        <Box marginTop={2}>
          <InputLabel
            sx={{ textAlign: "left", marginBottom: "2vh" }}
            htmlFor="confirmPassword"
          >
            Confirm New Password
          </InputLabel>
          <TextField
            inputProps={{ "data-testid": "confirm-password" }}
            onChange={handleChange}
            value={confirmPassword}
            name="confirmPassword"
            type="password"
            fullWidth
            id="confirmPassword"
            variant="outlined"
          />
        </Box>
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        <Box marginTop={4} textAlign={"left"}>
          <Grid container>
            <Grid item xs={6}>
              <Button
                onClick={changePasswordHandler}
                variant="contained"
                sx={{ bgcolor: "#DE8646" }}
                disabled={
                  disabled ||
                  password !== confirmPassword ||
                  password === "" ||
                  !password.match(passwordReg)
                }
              >
                Change Password
              </Button>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={1} md={4.5}></Grid>
      <Grid item xs={10} md={3}>
        {helpActive && (
          <Box
            borderRadius={2}
            border={1}
            padding={3}
            textAlign={"left"}
            marginTop={2}
          >
            <Typography fontSize={16}>
              Your password must consist of;
            </Typography>
            <List sx={{ listStyleType: "disc", fontSize: 16 }}>
              <ListItem sx={{ display: "list-item" }}>
                A minimum of 10 characters
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                At least one letter
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                At least one number
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                At least one of the following special characters: @$!%*?&
              </ListItem>
            </List>
            <Typography fontSize={16} fontWeight={"bold"}>
              Please note, only the special characters specified above can be
              used when creating a password.
            </Typography>
            <Typography fontSize={16} marginTop={2}>
              A good example is:{" "}
              <span style={{ fontWeight: "bold" }}>r50$K28val@iYxaY</span>
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={1} md={4.5}></Grid>
    </Grid>
  );
};

export default ChangePassword;
