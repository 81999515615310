import { Typography } from "@mui/material";
import { Box, Theme, useTheme } from "@mui/system";
import React from "react";
import Equilaw from "./Equilaw.png";
import Awards from "./Awards.png";

const styles = (theme: Theme) => ({
  text: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 2,
      paddingRight: 2,
    },
  },
});

const About = ({ bridging }: { bridging?: boolean }): JSX.Element => {
  const classes = styles(useTheme());

  return (
    <Box minHeight="100vh">
      <Box bgcolor="white">
        <Box paddingLeft={10} textAlign={"left"}>
          <Typography marginTop={4} color="#04334E" variant="h4">
            About Equilaw
          </Typography>
        </Box>
        <Box paddingTop={5}>
          <img
            style={{ maxHeight: "35vh", width: "83vw" }}
            src={Equilaw}
            alt="Equilaw"
          />
          <img
            style={{ width: "50vw", maxHeight: "20vh" }}
            src={Awards}
            alt="Awards"
          />
        </Box>
        <Box sx={classes.text} marginTop={2}>
          {bridging ? (
            <>
              <Typography textAlign={"left"} color="#8199A6">
                Our specialist lender service division offers bespoke finance
                solutions for clients, banks, mortgage lenders, and financial
                institutions across the UK. We leverage our nationwide coverage
                and multi award-winning service legal expertise to deliver
                tailored solutions that meet the unique requirements of each
                lender.
              </Typography>
              <Typography textAlign={"left"} color="#8199A6">
                At Equilaw, we’re proud to offer comprehensive bridging loan
                legal services. Unlike most firms, we have over 65 dedicated
                solicitors who travel across the UK daily to meet our clients in
                their homes or offices. This unique approach allows us to
                provide Independent Legal Advice (ILA), witness signatures, and
                certify documents, ensuring convenience and efficiency.
              </Typography>
              <Typography textAlign={"left"} color="#8199A6">
                This personal touch enables us to truly serve our clients,
                avoiding the need for them to seek out local solicitors, which
                often leads to additional costs and delays.
              </Typography>
            </>
          ) : (
            <>
              <Typography textAlign="left" color="#8199A6">
                Equilaw is a leading equity release solicitor in England and
                Wales, and we've been helping clients, like you, for the past 20
                years with their equity release applications.
              </Typography>
              <Typography textAlign="left" color="#8199A6">
                We take pride in assisting individuals like yourself in a highly
                professional manner, which has been recognised and awarded by
                industry experts. In 2022 we helped over 10,000 people like you
                in their equity release process.
              </Typography>
              <Typography textAlign="left" color="#8199A6">
                Should you have any questions, concerns, or need assistance at
                any point, remember that our support team is just a message
                away.{" "}
              </Typography>{" "}
              <Typography textAlign="left" color="#8199A6">
                {" "}
                This platform is your dedicated space to take the necessary
                actions that will pave the way for your equity release journey.
                Please complete the Initial Questionnaire, upload & read all of
                the necessary documents so that we can tailor our
                recommendations to your unique needs, we encourage you to dive
                into these steps right away.
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default About;
