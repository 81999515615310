export enum FormEntryType {
  TextEntry = 1,
  DateEntry = 2,
  OptionEntry = 3,
  ComboBoxEntry = 4,
  TextEditorEntry = 5,
  LabelEntry = 6,
  MultiSelectEntry = 7,
  AddItemsEntry = 8,
  Checkbox = 9,
  VerticalLayoutEntry = 10,
  HorizontalLayoutEntry = 11,
  CategoryEntry = 12,
  CategorizationEntry = 13,
  OccupierEntry = 14,
  AddNewEntry = 15,
  SummaryEntry = 16,
}

export interface FormItemAddItems {
  FormItems: FormItem[];
}

export interface FormItem {
  ItemType: number;
  Label: string;
  Narrative?: string;
  FullReferentialPath: string;
  Options?: string[];
  AnswerValue?: string;
  AddItems?: FormItemAddItems[];
  IsPhoneNumber?: boolean;
  IsEmail?: boolean;
}

export interface FormElementDisplay {
  rule: Dictionary<string, any>;
  text: string;
  label: string;
  type: string;
  scope: string;
  is_phone_number: boolean;
  is_email: boolean;
  options: ElementOptions;
  elements: FormElementDisplay[];

  entryType: FormEntryType;
  enums: string[];
  defaultValue: any;
  currentValue: any;
  required: boolean;
  Show: boolean;
  formElementDisplays: FormElementDisplay[];
  addItems?: AddItemsDisplayModel;
  Heading: string;
  numAddItems: number;
  DependScope: string;
  DependAction: string;
  DependValues: string[];
}

export interface AddItemDisplayModel {
  ItemDetails: FormElementDisplay[];
}

export interface AddItemsDisplayModel {
  Items: AddItemDisplayModel[];
}

export interface OptionDetails {
  type: string;
  elements: FormElementDisplay[];
}

export interface Conditionals {
  variable: string;
  comparator: string;
  depends_on: string;
  set_variable: string;
  depends_on_label: string;
  value_to_compare: string;
}

export interface ElementOptions {
  file: boolean;
  narrative: boolean;
  answer_type: string;
  referential_path: string;
  full_referential_path: string;
  referential_path_index: number;
  ov_code?: number;
  is_array: boolean;
  multi: boolean;
  hidden: boolean;
  multiselect: boolean;
  calculation: string;
  conditionals: Conditionals[];
  read_only: boolean;
  indented: boolean;
  detail: OptionDetails;
}

export interface FormElement {
  rule: Dictionary<string, any>;
  text: string;
  label: string;
  type: string;
  scope: string;
  options: ElementOptions;
  elements: FormElementDisplay[];
}

export interface FormUISchema {
  type: string;
  elements: FormElementDisplay[];
}

// export interface FormElementDisplay {
//   // Your properties go here
// }

export interface FormProperty {
  type: string;
  title: string;
  enum?: string[];
  format?: string;
  description?: string;
  items?: Dictionary<string, any>;
  properties?: Dictionary<string, FormProperty>;
}

export interface Dictionary<T> {
  [Key: string]: T;
}
// export interface FormSchema {
//   type: string;
//   title: string;
//   required?: string[];
//   properties: { [key: string]: FormProperty };
// }
export interface FormSchema {
  type: string;
  title: string;
  required?: string[];
  properties: Dictionary<string, FormProperty>;
}

// export interface FormTemplateVersion {
//   id: number;
//   conditional: string;
//   created_at: string;
//   data: { [key: string]: any };
//   fact_type_id: string;
//   form_template_id: number;
//   name: string;
//   schema: FormSchema;
//   ui_schema: FormUISchema;
//   updated_at: string;
//   version: number;
// }

export interface FormTemplateData {
  form_template_id: number;
  form_template_name: string;
  form_template_version_id: number;
  form_template_version_name: string;
  version: number;
  schema: FormSchema;
  ui_schema: FormUISchema;
  data: Dictionary<string, any>;
  created_at: string;
  updated_at: string;
}

export interface FormTemplateVersion {
  id: number;
  conditional: string;
  created_at: string;
  data: Dictionary<string, any>;
  fact_type_id: string;
  form_template_id: number;
  name: string;
  schema: FormSchema;
  ui_schema: FormUISchema;
  updated_at: string;
  version: number;
}

export interface FormTemplate {
  id: number;
  name: string;
  form_template_versions: FormTemplateVersion[];
}

export interface TemplateData {
  form_templates: FormTemplate[];
}

export interface SourcesResponse {
  data: FactTypesResponse;
}

export interface FactTypesResponse {
  fact_types: MetadataResponse[];
}

export interface MetadataResponse {
  metadata: EnumResponse;
}

export interface EnumResponse {
  Enum: string[];
}

export interface NovexLoginResponse {
  access_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
}

export interface InsertNovexInstructionRequest {
  form_template_name: string;
  username: string;
  data: InsertNovexDataOvCodesRequest;
}

export interface InsertNovexDataOvCodesRequest {
  dps_ov_codes: Dictionary<string, string>;
}

export interface InsertNovexInstructionResponse {
  step_function_arn: string;
  form_id: number;
  workflow_version_id: number;
  action_id: number;
  matter_id: number;
  form_template_version_id: number;
  form_template_name: string;
}

export interface UpdateNovexInstructionRequest {
  matter_id: number;
  data: Dictionary<string, string>;
}

export interface ItemSelected {
  FullReferentialPath: string;
  SelectedValue: string;
}

export interface CurrentValueModel {
  scope: string;
  referential_path: string;
  label: string;
  value: string;
  category: string;
}

export interface NovexAnswerObject {
  scope: string;
  fullReferentialPath: string;
  value: any;
  index: number;
  ovcode: number;
}

type AnswerValues = Record<string, NovexAnswerObject>;
