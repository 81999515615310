import { DocType, ChecklistItem } from "../types/checklist.d";

export const processChecklist = (
  checklist: ChecklistItem[]
): ChecklistItem[] => {
  checklist?.forEach((item: ChecklistItem) => {
    item.doc_uploaded = item.document_url !== null && item.document_url !== "";
    if (
      item.type === "form" &&
      item.document_received !== null &&
      item.document_received !== ""
    ) {
      item.form_submitted = true;
    }
    if (
      item.type === "document" &&
      item.document_received !== null &&
      item.document_received !== ""
    ) {
      item.doc_received = item.document_received;
      item.doc_uploaded = true;
    }
    item.doc_read = item.document_approved != null;
    item.doc_type = DocType.Html;
    item.doc_url = item.document_url;
  });
  return checklist;
};
