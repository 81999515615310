import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import TextView from "../Components/TextView/component";
import DropdownView from "../Components/Dropdown/component";
import SelectOneFromOptions from "../Components/SelectOneFromOptions/component";
import DateView from "../Components/DateView/component";
import { Button } from "@mui/material";

enum FormEntryType {
  TextEntry = 1,
  DateEntry = 2,
  OptionEntry = 3,
  ComboBoxEntry = 4,
  TextEditorEntry = 5,
  LabelEntry = 6,
  MultiSelectEntry = 7,
  AddItemsEntry = 8,
  Checkbox = 9,
  VerticalLayoutEntry = 10,
  HorizontalLayoutEntry = 11,
  CategoryEntry = 12,
  CategorizationEntry = 13,
  OccupierEntry = 14,
  AddNewEntry = 15,
  SummaryEntry = 16,
}

interface FormItem {
  ItemType: number;
  Label: string;
  Narrative?: string;
  FullReferentialPath: string;
  Options?: string[];
  AnswerValue?: string;
  AddItems?: FormItemAddItems[];
}

interface FormItemAddItems {
  Label: string;
  Dob?: string;
  Relationship?: string;
  ShowDetails: boolean;
  FormItems: FormItem[];
}

const Array = ({
  item,
  handleSaved,
  losingFocus,
  setLostFocus,
}: {
  item: FormItem;
  handleSaved: any;
  losingFocus: any;
  setLostFocus: (value: boolean) => void;
}): JSX.Element => {
  const [index, setIndex] = useState<number>(0);
  const [items, setItems] = useState<FormItemAddItems[]>(item.AddItems!);

  const nextStep = () => {
    setLostFocus(true);

    setTimeout(() => {
      if (index < items.length - 1) {
        setIndex(index + 1);
      } else {
        const newItems = [...items];

        const currItems = items[index].FormItems;

        for (const item of currItems) {
          item.AnswerValue = "";
        }

        newItems && newItems.push({ FormItems: currItems } as FormItemAddItems);

        setIndex(index + 1);
        setItems(newItems);
      }
    }, 200);
  };

  const previousStep = () => {
    setLostFocus(true);

    setTimeout(() => {
      setIndex(index - 1);
    }, 500);
  };

  return (
    <Box paddingTop={2}>
      {items &&
        items[index].FormItems?.map((formItem, i) => {
          return (
            <Box key={i} marginTop={2}>
              {formItem!.ItemType === FormEntryType.TextEntry ? (
                <TextView
                  item={formItem!}
                  handleSaved={handleSaved}
                  losingFocus={losingFocus}
                  showLabel={true}
                />
              ) : formItem!.ItemType === FormEntryType.OptionEntry ? (
                <DropdownView
                  item={formItem!}
                  handleSaved={handleSaved}
                  losingFocus={losingFocus}
                  showLabel={true}
                />
              ) : formItem!.ItemType === FormEntryType.ComboBoxEntry ? (
                <SelectOneFromOptions
                  item={formItem!}
                  handleSaved={handleSaved}
                  losingFocus={losingFocus}
                  showLabel={true}
                />
              ) : formItem!.ItemType === FormEntryType.DateEntry ? (
                <DateView
                  item={formItem!}
                  handleSaved={handleSaved}
                  losingFocus={losingFocus}
                  showLabel={true}
                />
              ) : (
                <></>
              )}
            </Box>
          );
        })}
      {index > 0 && (
        <Button
          onClick={previousStep}
          sx={{ marginTop: 3, marginRight: 2 }}
          variant="outlined"
        >
          Previous {item.Label}
        </Button>
      )}
      <Button onClick={nextStep} sx={{ marginTop: 3 }} variant="outlined">
        {items.length && index === items.length - 1
          ? `Add ${item.Label} (+)`
          : `Next ${item.Label}`}
      </Button>
    </Box>
  );
};

export default Array;
