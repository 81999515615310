import axios from "axios";
import { processChecklist } from "../utils/processChecklist";
import { ChecklistItem } from "../types/checklist";
import Cookies from "universal-cookie";
import { UserData } from "../types/user";

const clientApiUrl =
  process.env.REACT_APP_CLIENT_API_URL || "https://clients-uat.novex.software";

const getChecklist = async () => {
  const userDetails = JSON.parse(
    localStorage.getItem("user") || "{}"
  ) as UserData;

  const token = new Cookies().get("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = clientApiUrl + "/matters/" + userDetails.matter_id + "/checklist";
  const response = await axios.get(url, config);

  let checklistData = response.data as ChecklistItem[];
  var processedData = processChecklist(checklistData);

  return processedData;
};

export default getChecklist;

// class ChecklistService {

//     async getChecklist()
//     {
//         let _accountService = AccountService;
//         const token = await _accountService.login('matthews.will@gmail.com', 'Password123!');

//         const env = "-uat";

//         // let token = localStorage.getItem('token');

//         const config = {
//             headers: {
//                 Authorization: `Bearer ${token}`
//             }
//         };

//         // var matter_id = Cookies.get('matter_id');
//         const matter_id = 82641;

//         const url = "https://clients" + env + ".novex.software/matters/" + matter_id + "/checklist";

//         const response = await axios
//             .get(url, config);

//         let checklistData = response.data as ChecklistItem[];
//         var processedData = processChecklist(checklistData);

//         return processedData;

//     }

// }

// export default new ChecklistService();
