export type ChecklistProps = {
  items: ChecklistItem[];
};

export enum DocType {
  "Image" = 1,
  "Pdf" = 2,
  "Html" = 3,
}

export type ChecklistItem = {
  name: string;
  type: string;
  form_template_id: number;
  form_template_version_name: string;
  completed: boolean;
  optional: boolean;
  relevant_document: boolean;
  document_approved: string;
  document_dated: string;
  document_expiry: string;
  document_notes: string;
  document_received: string;
  document_url: string;
  document_urls: string[];
  upload_description: string;

  case_history_item_name: string;
  case_history_item_url: string;
  uploadHidden: string;

  doc_received: string;

  doc_uploaded: boolean;
  form_submitted: boolean;
  doc_read: boolean;
  doc_type: DocType;
  doc_url: string;
};
