import React from "react";
import { ChecklistItem } from "../../../types/checklist";
import { Box } from "@mui/system";
import { Button, Chip, Grid, Typography } from "@mui/material";
import { UserData } from "../../../types/user";
import downloadFile from "../../../utils/downloadFile";

const CompletedTaskItem = ({
  item,
  setReadonly,
  form_clickHandler,
}: {
  item: ChecklistItem;
  setReadonly: (readonly: boolean) => void;
  form_clickHandler: any;
}): JSX.Element => {
  console.log(item);

  const handleDownload = async () => {
    const userDetails = JSON.parse(
      localStorage.getItem("user") || "{}"
    ) as UserData;

    await downloadFile(
      item.case_history_item_name,
      item.case_history_item_url,
      userDetails.matter_id
    );
  };

  const handleDownloadItem = async () => {
    const userDetails = JSON.parse(
      localStorage.getItem("user") || "{}"
    ) as UserData;

    await downloadFile(
      item.name,
      item.document_urls[0] !== ""
        ? item.document_urls[0]
        : item.document_urls[1],
      userDetails.matter_id
    );
  };

  return (
    <Box padding={2} borderBottom={1} sx={{ borderColor: "#E6EBED" }}>
      <Grid container>
        <Grid item md={9} xs={6}>
          <Typography color="#8199A6">{item.name}</Typography>
        </Grid>
        <Grid item md={3} xs={6}>
          <Box>
            {item.type !== "readonly" ? (
              <Box>
                <Chip
                  sx={{ fontSize: 12, backgroundColor: "#FCF3ED" }}
                  size="small"
                  label={item.completed ? "APPROVED" : "UNDER REVIEW"}
                />
                <br />
                {(item.document_urls?.length > 0 &&
                  item.document_urls[0] !== "") ||
                  (item.document_urls?.length > 1 &&
                    item.document_urls[1] !== "" && (
                      <Button
                        sx={{
                          bgcolor: "#DE8646",
                          marginTop: 2,
                          textTransform: "none",
                        }}
                        variant="contained"
                        onClick={handleDownloadItem}
                      >
                        View
                      </Button>
                    ))}
              </Box>
            ) : (
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#DE8646",
                  color: "#DE8646",
                  textTransform: "none",
                }}
                onClick={handleDownload}
              >
                Read and Download
              </Button>
            )}
            <Typography marginTop={1} fontSize={10} color="#8199A6">
              {item.doc_received
                ? item.doc_received.includes("/")
                  ? `Completed ${item.doc_received}`
                  : item.doc_received.includes(":")
                  ? `Completed ${new Date(
                      item.doc_received
                    ).toLocaleDateString()}`
                  : item.doc_received
                : ""}
            </Typography>
            {item.type === "form" && (
              <Button
                sx={{ bgcolor: "#DE8646", marginTop: 1, textTransform: "none" }}
                variant="contained"
                onClick={() => {
                  setReadonly(true);
                  form_clickHandler(
                    item.form_template_version_name &&
                      item.form_template_version_name !== ""
                      ? item.form_template_version_name
                      : "IQ2"
                  );
                }}
              >
                View
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompletedTaskItem;
