import { Box } from "@mui/system";
import React from "react";
import { FormItem } from "../../../../../types/formTemplate";
import { Grid, Link, Typography } from "@mui/material";
import EditModal from "../EditModal/component";

const SummaryRow = ({
  item,
  index,
  formItems,
  setFormItem,
  handleSaved,
  readonly,
}: {
  readonly: boolean;
  item: FormItem;
  index: number;
  formItems: FormItem[];
  setFormItem: (item: FormItem) => void;
  handleSaved: (item: FormItem) => void;
}): JSX.Element => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <Box>
      {item.Label !== "" && (
        <Box
          sx={{ borderColor: "#E6EBED" }}
          borderBottom={index < formItems.length - 1 ? 1 : 0}
          paddingTop={2}
          paddingBottom={2}
          key={index}
        >
          <Grid container>
            <Grid xs={readonly ? 5 : 4}>
              <Typography fontWeight="bold">{item.Label}</Typography>
            </Grid>
            <Grid xs={readonly ? 7 : 6}>
              <Typography paddingLeft={3} color="#8199A6">
                {item.AnswerValue}
              </Typography>
            </Grid>
            <Grid xs={readonly ? 0 : 2}>
              {!readonly && (
                <Link
                  component="button"
                  color="#DE8646"
                  fontSize={"small"}
                  onClick={() => {
                    setFormItem(item);
                    setOpen(true);
                  }}
                >
                  Edit
                </Link>
              )}
            </Grid>
          </Grid>
          <EditModal
            open={open}
            setOpen={setOpen}
            formItem={item}
            handleSaved={handleSaved}
          />
        </Box>
      )}
    </Box>
  );
};

export default SummaryRow;
