import React, { useEffect, useState } from "react";
import { FormItem } from "../../../../types/formTemplate";
import {
  Box,
  Typography,
  Select,
  SelectChangeEvent,
  FormControl,
  Grid,
  OutlinedInput,
  Autocomplete,
  TextField,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import updateReferentialPaths from "../../../../utils/updateReferentialPaths";
import { UserData } from "../../../../types/user";

export default function DropdownView({
  item,
  handleSaved,
  losingFocus,
  showLabel,
  multiSelect,
}: {
  item: FormItem;
  handleSaved: any;
  losingFocus: any;
  showLabel: boolean;
  multiSelect?: boolean;
}) {
  const [formItem, setFormItem] = useState<FormItem>(item);
  const [answerValue, setAnswerValue] = useState<string>("");

  useEffect(() => {
    console.log(item);
    setFormItem(item);
    let answer = formItem.AnswerValue;
    if (answer) {
      setAnswerValue(answer);
    } else {
      setAnswerValue("");
    }
  }, [item]);

  const saveAnswer = async () => {
    if (answerValue === item.AnswerValue) {
      handleSaved(item);
    } else {
      const userDetails = JSON.parse(
        localStorage.getItem("user") || "{}"
      ) as UserData;
      await updateReferentialPaths(
        userDetails.matter_id,
        item.FullReferentialPath,
        answerValue
      );
      let newItem = item;
      newItem.AnswerValue = answerValue;
      handleSaved(newItem);
    }
  };

  React.useEffect(() => {
    if (losingFocus) {
      saveAnswer();
    }
  }, [losingFocus]);

  const handleChange = (event: SelectChangeEvent) => {
    setAnswerValue(event.target.value);
  };

  return (
    <Grid container>
      {showLabel ? (
        <Grid item xs={5}>
          <Typography paddingBottom={2} data-testid="formItemLabel">
            {item!.Label}
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={showLabel ? 7 : 12}>
        {multiSelect ? (
          <Autocomplete
            multiple
            id="tags-standard"
            options={formItem!.Options || []}
            getOptionLabel={(option) => option}
            value={answerValue.split(", ") || []}
            onChange={(event, value) => {
              setAnswerValue(value.join(", "));
            }}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
          />
        ) : (
          <Select
            inputProps={{
              "data-cy": `dropdown-input`,
            }}
            data-testid="dropdown"
            variant="outlined"
            value={answerValue}
            onChange={handleChange}
            fullWidth
          >
            {formItem!.Options!.map((option) => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </Select>
        )}
      </Grid>
    </Grid>
  );
}
