import React, { useEffect, useState } from "react";
import { ChecklistItem } from "../../../types/checklist";
import { Box } from "@mui/system";
import { Button, Divider, Modal, Typography } from "@mui/material";
import { getFileContent } from "../../../utils/downloadFile";
import SignatureCanvas from "react-signature-canvas";
import presignUpload from "../../../utils/presignUpload";
import { uploadHTML } from "../../../utils/uploadToS3";
import receiveChecklistItem from "../../../utils/receiveChecklistItem";
import getChecklist from "../../../services/ChecklistService";

const style = {
  position: "absolute" as "absolute",
  transform: "translate(-50%, -50%)",
  width: "95vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  top: "50%",
  left: "50%",
  height: "95%",
  display: "block",
};

export const ReadAndSign = ({
  item,
  open,
  setOpen,
  setChecklistData,
}: {
  item: ChecklistItem;
  open: boolean;
  setOpen: (open: boolean) => void;
  setChecklistData: (data: ChecklistItem[]) => void;
}) => {
  const [content, setContent] = useState<string>("");

  const completeDocument = async () => {
    const presignUploadResponse = await presignUpload(
      `${item.case_history_item_name} - Signed`,
      "text/html"
    );

    await uploadHTML(content, presignUploadResponse.presigned_url);

    await receiveChecklistItem(item.name, presignUploadResponse.document_url);

    const checklistData = await getChecklist();
    setChecklistData(checklistData);

    setOpen(false);
  };

  const addSignature = () => {
    const src = sigPad.getTrimmedCanvas().toDataURL("image/png");

    const c = content.replace("{ $sig1 }", `<img src='${src}'>`);

    setContent(c);
  };

  const addSignature2 = () => {
    const src = sig2Pad.getTrimmedCanvas().toDataURL("image/png");

    const c = content.replace("{ $sig2 }", `<img src='${src}'>`);

    setContent(c);
  };

  const addSignature3 = () => {
    const src = sig3Pad.getTrimmedCanvas().toDataURL("image/png");

    const c = content.replace("{ $sig3 }", `<img src='${src}'>`);

    setContent(c);
  };

  const addSignature4 = () => {
    const src = sig4Pad.getTrimmedCanvas().toDataURL("image/png");

    const c = content.replace("{ $sig4 }", `<img src='${src}'>`);

    setContent(c);
  };

  useEffect(() => {
    downloadFile(item.case_history_item_url);
  }, [item]);

  const downloadFile = async (url: string) => {
    const payload = await getFileContent(url, false);
    setContent(payload);
  };

  const [sigPad, setSigPad] = useState<any>({});
  const [sig2Pad, setSig2Pad] = useState<any>({});
  const [sig3Pad, setSig3Pad] = useState<any>({});
  const [sig4Pad, setSig4Pad] = useState<any>({});

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box sx={style}>
        <Typography fontSize={18}>Read and Sign</Typography>
        {content.length > 0 && (
          <>
            <Typography
              border={1}
              borderColor={"black"}
              dangerouslySetInnerHTML={{ __html: content }}
            ></Typography>
            {content?.includes("{ $sig1 }") && (
              <>
                <Box
                  marginTop={5}
                  sx={{ borderStyle: "dashed" }}
                  border={1}
                  borderColor="black"
                >
                  <Typography>
                    Signature 1: (Please draw your signature below using your
                    mouse or trackpad)
                  </Typography>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{
                      width: 8000,
                      height: 100,
                      className: "sigCanvas",
                    }}
                    ref={(ref) => {
                      setSigPad(ref);
                    }}
                  />
                  <Button
                    onClick={() => {
                      sigPad.clear();
                      setSigPad(sigPad);
                    }}
                  >
                    Clear
                  </Button>
                </Box>
                <Button
                  onClick={addSignature}
                  variant="contained"
                  sx={{ marginTop: 2 }}
                >
                  Add Signature
                </Button>
              </>
            )}
            {content?.includes("{ $sig2 }") && (
              <>
                <Box
                  marginTop={5}
                  sx={{ borderStyle: "dashed" }}
                  border={1}
                  borderColor="black"
                >
                  <Typography>
                    Signature 2: (Please draw your signature below using your
                    mouse or trackpad)
                  </Typography>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{
                      width: 8000,
                      height: 100,
                      className: "sigCanvas",
                    }}
                    ref={(ref) => {
                      setSig2Pad(ref);
                    }}
                  />
                  <Button
                    onClick={() => {
                      sig2Pad.clear();
                      setSig2Pad(sigPad);
                    }}
                  >
                    Clear
                  </Button>
                </Box>
                <Button
                  onClick={addSignature2}
                  variant="contained"
                  sx={{ marginTop: 2 }}
                >
                  Add Signature
                </Button>
              </>
            )}
            {content?.includes("{ $sig3 }") && (
              <>
                <Box
                  marginTop={5}
                  sx={{ borderStyle: "dashed" }}
                  border={1}
                  borderColor="black"
                >
                  <Typography>
                    Signature 3: (Please draw your signature below using your
                    mouse or trackpad)
                  </Typography>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{
                      width: 8000,
                      height: 100,
                      className: "sigCanvas",
                    }}
                    ref={(ref) => {
                      setSig3Pad(ref);
                    }}
                  />
                  <Button
                    onClick={() => {
                      sig3Pad.clear();
                      setSig3Pad(sigPad);
                    }}
                  >
                    Clear
                  </Button>
                </Box>
                <Button
                  onClick={addSignature3}
                  variant="contained"
                  sx={{ marginTop: 2 }}
                >
                  Add Signature
                </Button>
              </>
            )}
            {content?.includes("{ $sig4 }") && (
              <>
                <Box
                  marginTop={5}
                  sx={{ borderStyle: "dashed" }}
                  border={1}
                  borderColor="black"
                >
                  <Typography>
                    Signature 4: (Please draw your signature below using your
                    mouse or trackpad)
                  </Typography>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{
                      width: 8000,
                      height: 100,
                      className: "sigCanvas",
                    }}
                    ref={(ref) => {
                      setSig4Pad(ref);
                    }}
                  />
                  <Button
                    onClick={() => {
                      sig4Pad.clear();
                      setSig4Pad(sigPad);
                    }}
                  >
                    Clear
                  </Button>
                </Box>
                <Button
                  onClick={addSignature4}
                  variant="contained"
                  sx={{ marginTop: 2 }}
                >
                  Add Signature
                </Button>
              </>
            )}
          </>
        )}
        <Box marginTop={2}>
          <Divider />
          <Button
            sx={{ marginTop: 2, color: "white" }}
            variant="contained"
            color="success"
            onClick={completeDocument}
            disabled={
              content.includes("{ $sig1 }") ||
              content.includes("{ $sig2 }") ||
              content.includes("{ $sig3 }") ||
              content.includes("{ $sig4 }")
            }
          >
            Complete Document
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReadAndSign;
