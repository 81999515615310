import React, { useState, useEffect } from "react";

import FormTemplateService from "../../services/FormTemplateService";
import FormContainer from "../FormContainer/component";
import Dashboard from "../../components/Dashboard/component";
import Header from "../../components/Navigation/Header/component";
import OutstandingTasks from "../../components/OutstandingTasks/component";
import { MenuBar } from "../../components/Navigation/MenuBar/component";
import { NavItem } from "../../types/navItem";
import FAQ from "../../components/FAQ/component";
import { Box, CircularProgress, Grid } from "@mui/material";
import EditDetails from "../EditDetails/component";
import getClientDetails from "../../utils/getClientDetails";
import { ClientDetails } from "../../types/clientDetails";
import { UserData } from "../../types/user";
import Process from "../Process/component";
import getCaseStatus from "../../utils/getCaseStatus";
import UpdatesPage from "../Updates/component";
import { getCaseUpdates } from "../../utils/updates";
import Updates from "../../types/updates";
import About from "../About/component";
import BottomNav from "../BottomNav/component";
import { getDataFromApi } from "../../services/FormTemplateApiCalls";

export default function MainContainer({
  appMenuClickHandler,
}: {
  appMenuClickHandler: any;
}) {
  const [formTemplateService, setFormTemplateService] =
    useState<FormTemplateService>(FormTemplateService.getInstance());
  const [clientDetails, setClientDetails] = useState<ClientDetails>(
    {} as ClientDetails
  );
  const [currentElement, setCurrentElement] = useState<NavItem>({
    id: 0,
    name: "Home",
    icon: "home",
  });
  const [caseStatus, setCaseStatus] = useState<number>(1);
  const [updates, setUpdates] = useState<Updates[]>([]);
  const [readonly, setReadonly] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const initFormTemplateService = async (name: string) => {
    let formTemplateData = await getDataFromApi(name);

    formTemplateService.formTemplateData = formTemplateData;
    await formTemplateService.processFormTemplate();

    setFormTemplateService(formTemplateService);
  };

  useEffect(() => {
    loadClientDetails();
    loadCaseStatus();
    loadUpdates();
  }, []);

  const loadClientDetails = async () => {
    const userDetails = JSON.parse(
      localStorage.getItem("user") || "{}"
    ) as UserData;

    const cd = await getClientDetails(userDetails.matter_id);
    setClientDetails(cd);
    setLoading(false);
  };

  const loadCaseStatus = async () => {
    const userDetails = JSON.parse(
      localStorage.getItem("user") || "{}"
    ) as UserData;

    const cs = await getCaseStatus(userDetails.matter_id);
    setCaseStatus(cs.case_status || 1);
  };

  const loadUpdates = async () => {
    const userDetails = JSON.parse(
      localStorage.getItem("user") || "{}"
    ) as UserData;

    const u = await getCaseUpdates(userDetails.matter_id);
    setUpdates(u);
  };

  const menuClickHandler = (item: NavItem) => {
    let selectedItemName = item.name;
    switch (selectedItemName) {
      case "Logout":
        appMenuClickHandler(item);
        break;
      case "Dashboard":
        setCurrentElement(item);
        break;
      default:
        setCurrentElement(item);
        break;
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={0} md={2}>
          <MenuBar menuClickHandler={menuClickHandler} updates={updates} />
        </Grid>
        <Grid item md={10} xs={12}>
          <Grid container>
            {currentElement && currentElement.name !== "About" && (
              <Grid item xs={12}>
                <Dashboard
                  clientDetails={clientDetails}
                  menuClickHandler={menuClickHandler}
                />
                <Header caseStatus={caseStatus} clientDetails={clientDetails} />
              </Grid>
            )}
            <Grid item xs={12}>
              {currentElement && currentElement.name === "FAQ" ? (
                <FAQ bridging={clientDetails?.case_type === "Bridging"} />
              ) : currentElement && currentElement.name === "Home" ? (
                <>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <OutstandingTasks
                      bridging={clientDetails?.case_type === "Bridging"}
                      setReadonly={setReadonly}
                      updates={updates}
                      loadUpdates={loadUpdates}
                      items={[]}
                      menuClickHandler={menuClickHandler}
                      initFormTemplateService={initFormTemplateService}
                    />
                  )}
                </>
              ) : currentElement && currentElement.name === "Form" ? (
                <FormContainer
                  loadClientDetails={loadClientDetails}
                  readonly={readonly}
                  setCurrentElement={setCurrentElement}
                  service={formTemplateService}
                />
              ) : currentElement && currentElement.name === "Edit" ? (
                <EditDetails
                  setClientDetails={setClientDetails}
                  clientDetails={clientDetails}
                />
              ) : currentElement && currentElement.name === "Process" ? (
                <>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Process
                      caseStatus={caseStatus}
                      bridging={clientDetails?.case_type === "Bridging"}
                    />
                  )}
                </>
              ) : currentElement && currentElement.name === "Updates" ? (
                <UpdatesPage updates={updates} />
              ) : currentElement && currentElement.name === "About" ? (
                <About bridging={clientDetails?.case_type === "Bridging"} />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <BottomNav menuClickHandler={menuClickHandler} />
    </Box>
  );
}
