export class Constants
{
    static readonly baseUrl = "https://api.portal.equilaw.uk.com/";
    static readonly  maxRetryCount = 5;
    static readonly  maxAddItems = 8;
    
    static readonly  env = "-uat";
    static readonly  X_Hasura_Admin_Secret = "NsGLzqQnDEr4xmaxFtrEp4ygAbct";
    
    // public env = "-dev";
    // public X_Hasura_Admin_Secret = "womble";
    
    // public env = "";
    // public X_Hasura_Admin_Secret = "Hn*:g4Zxz\"9D[hr~";
}

