import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid, InputLabel, Link, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import updateRefPaths from "../../../../../utils/updateReferentialPaths";
import { UserData } from "../../../../../types/user";
import TextView from "../../../Components/TextView/component";
import DropdownView from "../../../Components/Dropdown/component";
import SelectOneFromOptions from "../../../Components/SelectOneFromOptions/component";
import DateView from "../../../Components/DateView/component";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

enum FormEntryType {
  TextEntry = 1,
  DateEntry = 2,
  OptionEntry = 3,
  ComboBoxEntry = 4,
  TextEditorEntry = 5,
  LabelEntry = 6,
  MultiSelectEntry = 7,
  AddItemsEntry = 8,
  Checkbox = 9,
  VerticalLayoutEntry = 10,
  HorizontalLayoutEntry = 11,
  CategoryEntry = 12,
  CategorizationEntry = 13,
  OccupierEntry = 14,
  AddNewEntry = 15,
  SummaryEntry = 16,
}

export default function EditModal({
  open,
  setOpen,
  formItem,
  handleSaved,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  formItem: any;
  handleSaved: (item: any) => void;
}) {
  const [losingFocus, setLosingFocus] = React.useState<boolean>(false);

  const handleSave = () => {
    setLosingFocus(true);

    setTimeout(() => {
      setOpen(false);
      setLosingFocus(false);
    }, 200);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box sx={style}>
        <Box sx={{ borderColor: "#E6EBED" }} borderBottom={1}>
          <Box padding={4}>
            <Grid container>
              <Grid item xs={11}></Grid>
              <Grid item xs={1}>
                <Box
                  sx={{
                    color: "#04334E",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  display="inline-flex"
                  onClick={() => setOpen(false)}
                >
                  <Link data-testid="close" component="button">
                    Close
                  </Link>
                  <Close sx={{ marginLeft: 1 }} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography fontSize={30}>{formItem.Label}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box padding={4}>
          <>
            {formItem!.ItemType === FormEntryType.TextEntry ? (
              <TextView
                item={formItem!}
                handleSaved={handleSaved}
                losingFocus={losingFocus}
                showLabel={false}
              />
            ) : formItem!.ItemType === FormEntryType.OptionEntry ? (
              <DropdownView
                item={formItem!}
                handleSaved={handleSaved}
                losingFocus={losingFocus}
                showLabel={false}
              />
            ) : formItem!.ItemType === FormEntryType.ComboBoxEntry ? (
              <SelectOneFromOptions
                item={formItem!}
                handleSaved={handleSaved}
                losingFocus={losingFocus}
                showLabel={false}
              />
            ) : formItem!.ItemType === FormEntryType.DateEntry ? (
              <DateView
                item={formItem!}
                handleSaved={handleSaved}
                losingFocus={losingFocus}
                showLabel={false}
              />
            ) : (
              <></>
            )}
          </>
        </Box>
        <Box paddingLeft={4} paddingBottom={4}>
          <Link
            onClick={() => {
              setOpen(false);
            }}
            component="button"
            color="rgba(222, 134, 70, 1);"
            data-testid="cancel"
          >
            Cancel
          </Link>
          <Button
            sx={{ marginLeft: 5, backgroundColor: "rgba(222, 134, 70, 1);" }}
            data-testid="save"
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
