import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import { UserData } from "./types/user";

import MainContainer from "./components/MainContainer/component";
import Login from "./components/Account/Login/component";
import { log } from "console";
import { NavItem } from "./types/navItem";
import Cookies from "universal-cookie";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Register from "./components/Account/Register/component";
import ForgottenPassword from "./components/Account/ForgottenPassword/component";
import ChangePassword from "./components/Account/ChangePassword/component";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [tempPassword, setTempPassword] = useState<boolean>(false);

  useEffect(() => {
    var userData: UserData | null = null;
    let jsonData = localStorage.getItem("user");
    if (jsonData) {
      userData = JSON.parse(jsonData) as UserData;
    }

    if (userData) {
      const token = new Cookies().get("token");

      if (!token) {
        setIsLoggedIn(false);
        localStorage.removeItem("user");
        return;
      }

      setTempPassword(userData.temporary_password);
      setIsLoggedIn(true);
    }
  }, []);

  const login_Handler = (status: any) => {
    // alert('login handler');

    if (status as boolean) {
      let jsonData = localStorage.getItem("user");
      if (jsonData) {
        console.log(jsonData);
        const userData = JSON.parse(jsonData) as UserData;
        setTempPassword(userData.temporary_password);
      }

      setIsLoggedIn(true);
    }
  };

  const menuClickHandler = (item: NavItem) => {
    // alert(item.name);
    let selectedItemName = item.name;
    switch (selectedItemName) {
      case "Logout":
        localStorage.removeItem("user");
        setIsLoggedIn(false);
        break;
      case "home":
        window.location.href = "/dashboard";
        break;
      default:
        break;
    }
  };

  return (
    <div className="App">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Material+Icons|Roboto:400,500,700|Source+Code+Pro"
      />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/gh/mlaursen/react-md@5.1.5/themes/react-md.teal-pink-200-light.min.css"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />

      {isLoggedIn ? (
        tempPassword ? (
          <ChangePassword />
        ) : (
          <MainContainer appMenuClickHandler={menuClickHandler} />
        )
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login login_Handler={login_Handler} />} />
            <Route path="/forgotten-password" element={<ForgottenPassword />} />
            <Route path="/account/register/:code" element={<Register />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
