import React, { useEffect, useState } from "react";
import FormTemplateService from "../../../services/FormTemplateService";
import {
  CurrentValueModel,
  FormElementDisplay,
  FormItem,
} from "../../../types/formTemplate";
import Summary from "../Form/Summary/component";
import { Box } from "@mui/system";
import { getCurrentValuesData } from "../../../services/FormTemplateApiCalls";

const FormSummary = ({
  service,
  category,
}: {
  service: FormTemplateService;
  category: number;
}): JSX.Element => {
  const [formItems, setFormItems] = useState<FormItem[] | null>(null);
  const [formTemplateService, setFormTemplateService] =
    useState<FormTemplateService | null>(service);
  const [currentValues, setCurrentValues] = useState<
    CurrentValueModel[] | null
  >(null);
  const [formElementDisplay, setFormElementDisplay] = useState<
    FormElementDisplay[]
  >([]);

  useEffect(() => {
    fetchData();
  }, [category]);

  const fetchData = async () => {
    if (formTemplateService) {
      await formTemplateService.processVariables();

      let formTemplateVersionId = formTemplateService.formTemplateData
        ?.form_template_version_id as number;
      let currentValuesData = await getCurrentValuesData(formTemplateVersionId);
      let currentValues = await formTemplateService.processCurrentValues(
        currentValuesData
      );

      setCurrentValues(currentValues);
      await formTemplateService.processFormTemplate();
      await formTemplateService.setCurrentValues(currentValues);
      await formTemplateService.insertCurrentValues(currentValues);

      let cats = await formTemplateService.getCategories();
      setFormElementDisplay(cats!);
      const frmItems = await formTemplateService.getFormItems(category);
      // setCurrentQuestion(startQuestion);

      setFormItems(frmItems);
    } else {
      return null;
    }
  };

  return (
    <Box padding={2} bgcolor="white" textAlign={"left"}>
      <Summary
        readonly={true}
        formItems={formItems || []}
        setFormItem={() => {}}
        handleSaved={() => {}}
      />
    </Box>
  );
};

export default FormSummary;
