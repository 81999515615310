import axios from "axios";
import Cookies from "universal-cookie";

const clientApiUrl =
  process.env.REACT_APP_CLIENT_API_URL || "https://clients-uat.novex.software";

export const submitForm = async (
  data: any,
  matterId: number,
  formTemplateName: string
) => {
  const apiURL = `${clientApiUrl}/forms`;

  const token = new Cookies().get("token");

  const payload = {
    data: data,
    form_template_name: formTemplateName,
    matter_id: matterId,
  };

  try {
    const response = await axios.post(apiURL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err) {
    console.error(err);
  }
};
