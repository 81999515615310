import React, { useEffect, useState } from "react";
import { FormItem } from "../../../../types/formTemplate";

import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";
import updateReferentialPaths from "../../../../utils/updateReferentialPaths";
import { UserData } from "../../../../types/user";

export default function SelectOneFromOptions({
  item,
  handleSaved,
  losingFocus,
  showLabel,
}: {
  item: FormItem;
  handleSaved: any;
  losingFocus: any;
  showLabel: boolean;
}) {
  const [formItem, setFormItem] = useState<FormItem>(item);
  const [answerValue, setAnswerValue] = useState<string>("");

  useEffect(() => {
    setFormItem(item);
    let answer = formItem.AnswerValue;
    if (answer) {
      setAnswerValue(answer);
    } else {
      setAnswerValue("");
    }
  }, [item]);

  const saveAnswer = async () => {
    if (answerValue === item.AnswerValue) {
      handleSaved(item);
    } else {
      const userDetails = JSON.parse(
        localStorage.getItem("user") || "{}"
      ) as UserData;
      await updateReferentialPaths(
        userDetails.matter_id,
        item.FullReferentialPath,
        answerValue
      );
      let newItem = item;
      newItem.AnswerValue = answerValue;
      handleSaved(newItem);
    }
  };

  React.useEffect(() => {
    if (losingFocus) {
      saveAnswer();
    }
  }, [losingFocus]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnswerValue((event.target as HTMLInputElement).value);
  };

  // if (formItem!.Options) {
  return (
    <Box>
      {showLabel ? (
        <Grid container>
          <Grid item xs={5}>
            <Typography paddingBottom={2} data-testid="formItemLabel">
              {item!.Label}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <RadioGroup
              data-testid="radio-group"
              value={answerValue}
              row
              onChange={handleChange}
              sx={{ marginLeft: 2, minWidth: 120 }}
            >
              {formItem!.Options!.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option}
                  label={option}
                  control={
                    <Radio
                      sx={{
                        color: "#757575",
                        "&.Mui-checked": {
                          color: "#DE8646",
                        },
                      }}
                    />
                  }
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
      ) : (
        <RadioGroup
          data-testid="radio-group"
          value={answerValue}
          onChange={handleChange}
          sx={{ minWidth: 120 }}
        >
          {formItem!.Options!.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option}
              label={option}
              control={
                <Radio
                  sx={{
                    color: "#757575",
                    "&.Mui-checked": {
                      color: "#DE8646",
                    },
                  }}
                />
              }
            />
          ))}
        </RadioGroup>
      )}
    </Box>
  );
}
