import img0 from "../assets/svg/001-add.svg"
import img1 from "../assets/svg/002-affiliate.svg"
import img2 from "../assets/svg/003-alert.svg"
import img3 from "../assets/svg/004-back.svg"
import img4 from "../assets/svg/005-banned.svg"
import img5 from "../assets/svg/006-notification.svg"
import img6 from "../assets/svg/007-notification.svg"
import img7 from "../assets/svg/008-bluetooth.svg"
import img8 from "../assets/svg/009-bookmark.svg"
import img9 from "../assets/svg/010-menu.svg"
import img10 from "../assets/svg/011-cart.svg"
import img11 from "../assets/svg/012-buy.svg"
import img12 from "../assets/svg/013-camera.svg"
import img13 from "../assets/svg/014-chat.svg"
import img14 from "../assets/svg/015-checked.svg"
import img15 from "../assets/svg/016-cloud.svg"
import img16 from "../assets/svg/017-cloud-storage.svg"
import img17 from "../assets/svg/018-no-connection.svg"
import img18 from "../assets/svg/019-connection.svg"
import img19 from "../assets/svg/020-crop.svg"
import img20 from "../assets/svg/021-date.svg"
import img21 from "../assets/svg/022-delete.svg"
import img22 from "../assets/svg/023-dislike.svg"
import img23 from "../assets/svg/024-menu.svg"
import img24 from "../assets/svg/025-layout.svg"
import img25 from "../assets/svg/026-dollar.svg"
import img26 from "../assets/svg/027-menu.svg"
import img27 from "../assets/svg/028-down-arrow.svg"
import img28 from "../assets/svg/029-download.svg"
import img29 from "../assets/svg/030-edit.svg"
import img30 from "../assets/svg/031-exit.svg"
import img31 from "../assets/svg/032-expand.svg"
import img32 from "../assets/svg/033-expand.svg"
import img33 from "../assets/svg/034-female.svg"
import img34 from "../assets/svg/035-file.svg"
import img35 from "../assets/svg/036-folder.svg"
import img36 from "../assets/svg/037-folder-network.svg"
import img37 from "../assets/svg/038-globe.svg"
import img38 from "../assets/svg/039-enter.svg"
import img39 from "../assets/svg/040-exit.svg"
import img40 from "../assets/svg/041-grid.svg"
import img41 from "../assets/svg/042-home.svg"
import img42 from "../assets/svg/043-image.svg"
import img43 from "../assets/svg/044-information.svg"
import img44 from "../assets/svg/045-invisible.svg"
import img45 from "../assets/svg/046-menu.svg"
import img46 from "../assets/svg/047-label.svg"
import img47 from "../assets/svg/048-left-arrow.svg"
import img48 from "../assets/svg/049-like.svg"
import img49 from "../assets/svg/050-clock.svg"
import img50 from "../assets/svg/051-unlock.svg"
import img51 from "../assets/svg/052-male.svg"
import img52 from "../assets/svg/053-menu.svg"
import img53 from "../assets/svg/054-message.svg"
import img54 from "../assets/svg/055-minimize.svg"
import img55 from "../assets/svg/056-minimize.svg"
import img56 from "../assets/svg/057-mute.svg"
import img57 from "../assets/svg/058-list.svg"
import img58 from "../assets/svg/059-new-file.svg"
import img59 from "../assets/svg/060-add-folder.svg"
import img60 from "../assets/svg/061-add-user.svg"
import img61 from "../assets/svg/062-next.svg"
import img62 from "../assets/svg/063-pin.svg"
import img63 from "../assets/svg/064-pin.svg"
import img64 from "../assets/svg/065-price-tag.svg"
import img65 from "../assets/svg/066-printer.svg"
import img66 from "../assets/svg/067-private.svg"
import img67 from "../assets/svg/068-question.svg"
import img68 from "../assets/svg/069-trash.svg"
import img69 from "../assets/svg/070-forward.svg"
import img70 from "../assets/svg/071-forward.svg"
import img71 from "../assets/svg/072-refresh.svg"
import img72 from "../assets/svg/073-right-arrow.svg"
import img73 from "../assets/svg/074-save.svg"
import img74 from "../assets/svg/075-search.svg"
import img75 from "../assets/svg/076-security.svg"
import img76 from "../assets/svg/077-setting.svg"
import img77 from "../assets/svg/078-power.svg"
import img78 from "../assets/svg/079-social.svg"
import img79 from "../assets/svg/080-decrease-volume.svg"
import img80 from "../assets/svg/081-increase-volume.svg"
import img81 from "../assets/svg/082-star.svg"
import img82 from "../assets/svg/083-statistic.svg"
import img83 from "../assets/svg/084-sun.svg"
import img84 from "../assets/svg/085-brightness.svg"
import img85 from "../assets/svg/086-telephone.svg"
import img86 from "../assets/svg/087-top-up.svg"
import img87 from "../assets/svg/088-torch.svg"
import img88 from "../assets/svg/089-torch.svg"
import img89 from "../assets/svg/090-transaction.svg"
import img90 from "../assets/svg/091-undo.svg"
import img91 from "../assets/svg/092-undo.svg"
import img92 from "../assets/svg/093-up-arrow.svg"
import img93 from "../assets/svg/094-upload.svg"
import img94 from "../assets/svg/095-user.svg"
import img95 from "../assets/svg/096-video.svg"
import img96 from "../assets/svg/097-eye.svg"
import img97 from "../assets/svg/098-wallet.svg"
import img98 from "../assets/svg/099-withdraw.svg"
import img99 from "../assets/svg/100-edit.svg"
import img100 from "../assets/svg/equilaw_logo.svg"
import img101 from "../assets/svg/line.svg"









export const svgimages : {[key:string] : any} = {
    "add" : img0,
    "affiliate" : img1,
    "alert" : img2,
    "back" : img3,
    "banned" : img4,
    "notification" : img5,
    "notification2" : img6,
    "bluetooth" : img7,
    "bookmark" : img8,
    "menu" : img9,
    "cart" : img10,
    "buy" : img11,
    "camera" : img12,
    "chat" : img13,
    "checked" : img14,
    "cloud" : img15,
    "cloud-storage" : img16,
    "no-connection" : img17,
    "connection" : img18,
    "crop" : img19,
    "date" : img20,
    "delete" : img21,
    "dislike" : img22,
    "menu2" : img23,
    "layout" : img24,
    "dollar" : img25,
    "menu3" : img26,
    "down-arrow" : img27,
    "download" : img28,
    "edit" : img29,
    "exit" : img30,
    "expand" : img31,
    "expand2" : img32,
    "female" : img33,
    "file" : img34,
    "folder" : img35,
    "folder-network" : img36,
    "globe" : img37,
    "enter" : img38,
    "exit2" : img39,
    "grid" : img40,
    "home" : img41,
    "image" : img42,
    "information" : img43,
    "invisible" : img44,
    "menu4" : img45,
    "label" : img46,
    "left-arrow" : img47,
    "like" : img48,
    "clock" : img49,
    "unlock" : img50,
    "male" : img51,
    "menu5" : img52,
    "message" : img53,
    "minimize" : img54,
    "minimize2" : img55,
    "mute" : img56,
    "list" : img57,
    "new-file" : img58,
    "add-folder" : img59,
    "add-user" : img60,
    "next" : img61,
    "pin" : img62,
    "pin2" : img63,
    "price-tag" : img64,
    "printer" : img65,
    "private" : img66,
    "question" : img67,
    "trash" : img68,
    "forward" : img69,
    "forward2" : img70,
    "refresh" : img71,
    "right-arrow" : img72,
    "save" : img73,
    "search" : img74,
    "security" : img75,
    "setting" : img76,
    "power" : img77,
    "social" : img78,
    "decrease-volume" : img79,
    "increase-volume" : img80,
    "star" : img81,
    "statistic" : img82,
    "sun" : img83,
    "brightness" : img84,
    "telephone" : img85,
    "top-up" : img86,
    "torch" : img87,
    "torch2" : img88,
    "transaction" : img89,
    "undo" : img90,
    "undo2" : img91,
    "up-arrow" : img92,
    "upload" : img93,
    "user" : img94,
    "video" : img95,
    "eye" : img96,
    "wallet" : img97,
    "withdraw" : img98,
    "edit2" : img99,
    "equilaw-logo" : img100,
    "line" : img101,

    
}