import {
  Button,
  Grid,
  InputLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { svgimages } from "../../../utils/svgimages";
import { Link as RouterLink } from "react-router-dom";
import resetPassword from "../../../utils/resetPassword";

const ForgottenPassword = (): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleResetPassword = async () => {
    await resetPassword(email);
    setEmailSent(true);
  };

  return (
    <Grid container>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        <Box textAlign={"center"} marginTop={2}>
          <img
            width="100%"
            src={svgimages["equilaw-logo"]}
            alt="Equilawlogo1651"
          />
        </Box>
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        <Box marginTop={8}>
          {emailSent ? (
            <>
              <Typography>
                We will be sending you an email shortly. This email will outline
                the additional steps required to reset your password.
              </Typography>

              <Typography marginTop={2}>
                We would request that you monitor your junk/spam folders if you
                have not received an email within the next 30 minutes.
              </Typography>
            </>
          ) : (
            <>
              <InputLabel
                sx={{ textAlign: "left", marginBottom: "2vh" }}
                htmlFor="email"
              >
                Email
              </InputLabel>
              <TextField
                onChange={handleChange}
                value={email}
                fullWidth
                id="email"
                name="email"
                variant="outlined"
              />{" "}
            </>
          )}
        </Box>
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        {!emailSent && (
          <Box marginTop={4} textAlign={"left"}>
            <Grid container>
              <Grid item xs={6}>
                <Button
                  disabled={email === ""}
                  onClick={handleResetPassword}
                  variant="contained"
                  sx={{ bgcolor: "#DE8646" }}
                >
                  Reset Password
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Link
                  component={RouterLink}
                  fontSize={14}
                  color="#DE8646"
                  to="/"
                >
                  Login
                </Link>
              </Grid>
            </Grid>
          </Box>
        )}
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
    </Grid>
  );
};

export default ForgottenPassword;
