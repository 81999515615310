import axios from "axios";
import Cookies from "universal-cookie";

import { UserData } from "../types/user";
import { FormTemplateData } from "../types/formTemplate.d";

const clientApiURL =
  process.env.REACT_APP_CLIENT_API_URL || "https://clients-uat.novex.software";

const formsApiURL =
  process.env.REACT_APP_FORMS_API_URL || "https://forms-uat.novex.software";

export const getDataFromApi = async (name: string) => {
  const token = new Cookies().get("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const url = `${clientApiURL}/form-template-versions/latest?name=${name}`;

  const response = await axios.get(url, config);

  const data = response.data as FormTemplateData;

  return data as FormTemplateData;
};

export const getCurrentValuesData = async (
  form_template_version_id: number
): Promise<any | null> => {
  console.log(`form_template_version_id: ${form_template_version_id}`);
  const formTemplateVersionId = form_template_version_id;

  const userData: UserData | null = JSON.parse(
    localStorage.getItem("user") as any
  );

  if (!userData) {
    throw new Error("User data not found");
  }

  let matter_id = userData.matter_id as number;

  const token = new Cookies().get("token");
  const url: string = `${formsApiURL}/matters/${matter_id}/forms/${formTemplateVersionId}`;

  console.log(`url: ${url}`);

  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  console.log(`response.data: ${response.data}`);

  if (!response.data) {
    console.error("Response data is null");
    return null;
  }

  return response.data;
};
