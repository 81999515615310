import axios from "axios";
import Cookies from "universal-cookie";
import { UserData } from "../types/user";

const clientApiUrl =
  process.env.REACT_APP_CLIENT_API_URL || "https://clients-uat.novex.software";

const receiveChecklistItem = async (
  name: string,
  document_url: string
): Promise<any> => {
  const userDetails = JSON.parse(
    localStorage.getItem("user") || "{}"
  ) as UserData;

  try {
    const response = await axios.post(
      `${clientApiUrl}/matters/${userDetails.matter_id}/checklist`,
      {
        name: name,
        document_url: document_url,
      },
      {
        headers: {
          Authorization: `Bearer ${new Cookies().get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

export default receiveChecklistItem;
