import axios from "axios";
import { UserData } from "../types/user";
import { add } from "date-fns";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

export type jwtCookie = {
  expires: Date;
  path: string;
  sameSite: "strict";
  secure?: boolean;
};

const identityUrl =
  process.env.REACT_APP_IDENTITY_URL || "https://identity-uat.novex.software";

class AccountService {
  async login(username: string, password: string) {
    const url = `${identityUrl}/login-client?scope=all&username=${username}&password=${encodeURIComponent(
      password
    )}&grant_type=password`;

    const config = {
      headers: {
        Authorization: "Basic MTExMToxMTExMTE=",
      },
    };

    const response = await axios.post(url, null, config);
    if (response.data.access_token) {
      const expiryTime: Date = add(new Date(), {
        seconds: response.data.expires_in,
      });
      const cookieOptions: jwtCookie = {
        expires: expiryTime,
        path: "/",
        sameSite: "strict" as "strict",
        secure: window.location.origin.indexOf("equilaw") !== -1 || undefined,
      };
      const cookies = new Cookies();
      cookies.set("token", response.data.access_token, cookieOptions);

      const tokenData = jwt_decode(response.data.access_token) as any;

      let userData: UserData | null = localStorage.getItem("user") as any;

      if (userData) {
        userData.jwToken = response.data.access_token as string;
        userData.matter_id = tokenData.matter_id as number;
        userData.temporary_password = tokenData.temporary_password as boolean;
        userData.email = tokenData.email_address as string;

        localStorage.setItem("user", JSON.stringify(userData));
      } else {
        let newUserData: UserData = {
          temporary_password: false,
          id: "",
          username: "",
          password: "",
          email: "",
          roles: [],
          isVerified: false,
          matter_id: 0,
          clientNames: "",
          instructionFullAddress: "",
          ifaName: "",
          ifaCompany: "",
          progressModels: [],
          jwToken: "",
        };

        newUserData.jwToken = response.data.access_token as string;
        newUserData.matter_id = tokenData.matter_id as number;
        newUserData.temporary_password =
          tokenData.temporary_password as boolean;
        newUserData.email = tokenData.email_address as string;
        localStorage.setItem("user", JSON.stringify(newUserData));
      }
    }

    let token = response.data.access_token;
    return token;
  }
}

const accountService = new AccountService();

export default accountService;
