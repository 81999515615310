import axios from "axios";
import Cookies from "universal-cookie";

const clientApiUrl =
  process.env.REACT_APP_CLIENT_API_URL || "https://clients-uat.novex.software";

const getCaseStatus = async (matterId: number) => {
  const apiURL = `${clientApiUrl}/matters/${matterId}/status`;

  const response = await axios.get(apiURL, {
    headers: {
      Authorization: `Bearer ${new Cookies().get("token")}`,
    },
  });

  return response.data;
};

export default getCaseStatus;
