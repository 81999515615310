import {
  Button,
  Grid,
  InputLabel,
  Link,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { svgimages } from "../../../utils/svgimages";
import { Box } from "@mui/system";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import register from "../../../utils/registerUser";
import PasswordStrengthBar from "react-password-strength-bar";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Register = (): JSX.Element => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [helpActive, setHelpActive] = useState<boolean>(false);

  const { code } = useParams();
  const query = useQuery();

  const email = query.get("email");
  const name = query.get("name");

  const registerHandler = async () => {
    await register(email || "", password, code || "", name || "");
    window.location.href = "/";
  };

  const passwordReg =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\.@$!%*#?&])[A-Za-z\d@$!%*#?&\.]{10,}$/;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "password") {
      setPassword(event.target.value);
    } else if (event.target.name === "confirmPassword") {
      setConfirmPassword(event.target.value);
    }
  };

  return (
    <Grid container>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        <Box textAlign={"center"} marginTop={2}>
          <img
            width="100%"
            src={svgimages["equilaw-logo"]}
            alt="Equilawlogo1651"
          />
        </Box>
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        <Box marginTop={2}>
          <InputLabel
            sx={{ textAlign: "left", marginBottom: "2vh" }}
            htmlFor="password"
          >
            Password
          </InputLabel>
          <TextField
            data-testid="password"
            onChange={handleChange}
            value={password}
            name="password"
            type="password"
            fullWidth
            id="password"
            variant="outlined"
            onFocus={() => {
              setHelpActive(true);
            }}
            onBlur={() => {
              setHelpActive(false);
            }}
          />
          <PasswordStrengthBar minLength={10} password={password} />
        </Box>
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        <Box marginTop={2}>
          <InputLabel
            sx={{ textAlign: "left", marginBottom: "2vh" }}
            htmlFor="confirmPassword"
          >
            Confirm Password
          </InputLabel>
          <TextField
            data-testid="confirm-password"
            onChange={handleChange}
            value={confirmPassword}
            name="confirmPassword"
            type="password"
            fullWidth
            id="confirmPassword"
            variant="outlined"
          />
        </Box>
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={8} md={3}>
        <Box marginTop={4} textAlign={"left"}>
          <Grid container>
            <Grid item xs={6}>
              <Button
                onClick={registerHandler}
                variant="contained"
                sx={{ bgcolor: "#DE8646" }}
                disabled={
                  password !== confirmPassword ||
                  password === "" ||
                  !password.match(passwordReg)
                }
              >
                Register
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Link to="/" fontSize={14} color="#DE8646" component={RouterLink}>
                Already registered? Click here to login.
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={2} md={4.5}></Grid>
      <Grid item xs={1} md={4.5}></Grid>
      <Grid item xs={10} md={3}>
        {helpActive && (
          <Box
            borderRadius={2}
            border={1}
            padding={3}
            textAlign={"left"}
            marginTop={2}
          >
            <Typography fontSize={16}>
              Your password must consist of;
            </Typography>
            <List sx={{ listStyleType: "disc", fontSize: 16 }}>
              <ListItem sx={{ display: "list-item" }}>
                A minimum of 10 characters
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                At least one letter
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                At least one number
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                At least one of the following special characters: @$!%*?&
              </ListItem>
            </List>
            <Typography fontSize={16} fontWeight={"bold"}>
              Please note, only the special characters specified above can be
              used when creating a password.
            </Typography>
            <Typography fontSize={16} marginTop={2}>
              A good example is:{" "}
              <span style={{ fontWeight: "bold" }}>r50$K28val@iYxaY</span>
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={1} md={4.5}></Grid>
    </Grid>
  );
};

export default Register;
