import axios from "axios";

const identityUrl =
  process.env.REACT_APP_IDENTITY_URL || "https://identity-uat.novex.software";

const register = async (
  email: string,
  password: string,
  code: string,
  name: string
) => {
  var bodyFormData = new FormData();

  bodyFormData.append("email", email);
  bodyFormData.append("password", password);
  bodyFormData.append("code", code);
  bodyFormData.append("name", name);

  const url = `${identityUrl}/register-client`;

  try {
    const response = await axios.post(url, bodyFormData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    console.log(response);
  } catch (error) {
    console.log(error);
  }
};

export default register;
