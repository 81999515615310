import axios from "axios";
import Cookies from "universal-cookie";

const clientApiUrl =
  process.env.REACT_APP_CLIENT_API_URL || "https://clients-uat.novex.software";

const updateRefPaths = async (
  matterId: number,
  refPath: string,
  value: string | number,
  token?: string
) => {
  const apiURL = `${clientApiUrl}/referential-paths`;

  return axios.post(
    apiURL,
    {
      matter_id: matterId,
      data: { [refPath]: value },
    },
    {
      headers: {
        Authorization: `Bearer ${token || new Cookies().get("token")}`,
      },
    }
  );
};

export default updateRefPaths;
