import styled, { css } from 'styled-components'

export const Layout = styled.div`

.hidden {
    display: none;
}

.uploadShow {
    display: block;
}

.leftCol.grid-item {
    border-radius: 10px 0px 0px 10px;
}

.rightCol.grid-item {
    border-radius: 0px 10px 10px 0px;
    justify-content: center;
}

.grid-wrapper {
    display: contents;
}

.nameStyle {
    font-size: large;
    color: lightgray;
    text-align: left;
}

.descriptionStyle {
    font-size: small;   
    color: darkgray;
}

.grid-container {
    background-color: rgba(242,244,255,1);
    display: grid;
    grid-gap: 3vw;
    grid-template-columns: 30% 70%;
    width: 90vw;
    margin-left: 3vw;
    margin-right: 3vw;
/*    height: 100vh;*/
}
.grid-row-wrapper {
    display: contents;
    border-radius: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    border-color: rgba(255, 255, 255, 0.3);
}

    .grid-row-wrapper > .grid-item {
        background: rgb(32 38 72);
        color: white;
        padding: 4px 0px 4px 10px;
        display: flex;
        align-items: center;
    }
.gridOutlineBtn {
    border-color: #f5080a;
    border-width: 1px;
    color: white;
    background-color: rgb(32 38 72);
    padding: 5px;
    width: 100px;
}
.gridOutlineBtn:hover {
    border-color: rgb(255, 43, 43);
    border-width: 1px;
    color: rgba(29,65,102,1);
    background-color: white;
    padding: 5px;
}

.gridOutlineBtnGreen {
border-color: #24a80f;
border-width: 1px;
color: white;
background-color: rgb(32 38 72);
padding: 5px;
width: 100px;
}

.gridOutlineBtnGreen:hover {
    border-color: #24a80f;
    border-width: 1px;
    color: rgba(29,65,102,1);
    background-color: white;
    padding: 5px;
}

.navOutlineBtn {
border-color: white;
border-width: 1px;
color: rgba(29,65,102,1);
background-color: white;
padding: 5px;
width: 80px;
}

.navOutlineBtn:active {
    border-color: red;
    border-width: 1px;
    color: rgba(29,65,102,1);
    background-color: transparent;
    padding: 5px;
}

.navOutlineBtn:focus {
    border-color: white;
    border-width: 1px;
    color: rgba(29,65,102,1);
    background-color: rgb(20, 20, 20);
    padding: 5px;
}

.navOutlineBtn:hover {
    border-color: white;
    border-width: 1px;
    color: rgba(29,65,102,1);
    background-color: white;
    padding: 5px;
    width: 80px;
}

.navOutlineBtn:active {
    border-color: red;
    border-width: 1px;
    color: rgba(29,65,102,1);
    background-color: transparent;
    padding: 5px;
}

.navOutlineBtn:focus {
    border-color: white;
    border-width: 1px;
    color: rgba(29,65,102,1);
    background-color: rgb(20, 20, 20);
    padding: 5px;
}

.navOutlineBtn:hover {
    border-color: white;
    border-width: 1px;
    color: white;
    background-color: rgba(29,65,102,1);
    padding: 5px;
}

.grid-container2 {
    background-color: white;
    display: grid;
    grid-template-columns: 80px 3fr 1fr;
    grid-template-rows: auto;
    grid-row-gap: 12px;
    justify-content: center;
    color: rgba(29,65,102,1);
    position: relative;
    top: 204px; 
    left: 32.5px;
    width: 60vw;

}

.spin-container {
    height: 100%
}



.leftCol {
    background-color: rgba(29,65,102,1);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 0px;
    grid-column-start: 1;
    vertical-align: central;
}

.leftColSpan {
    background-color: rgba(29,65,102,1);
    padding-top: 10px;
    padding-bottom: 10px;
    grid-column-start: 1;
    grid-column-end: 3;
}

.midCol {
    background-color: rgba(29,65,102,1);
    grid-column-start: 2;
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: central;
}

.rightCol {
    background-color: rgba(29,65,102,1);
    grid-column-start: 3;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.leftColumn {
    background-color: rgba(29,65,102,1);
    border-radius: 10px;
    min-height: 10vh;
    padding: 30px
}

.rightColumn {
    background-color: rgba(29,65,102,1);
    border-radius: 10px;
    min-height: 50vh;
    padding: 30px
}
  .todoapp {
    background: #fff;
    margin: 130px 0 40px 0;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 25px 50px 0 rgba(0, 0, 0, 0.1);
  }

  .todoapp input::-webkit-input-placeholder {
    font-style: italic;
    font-weight: 300;
    color: #e6e6e6;
  }

  .todoapp input::-moz-placeholder {
    font-style: italic;
    font-weight: 300;
    color: #e6e6e6;
  }

  .todoapp input::input-placeholder {
    font-style: italic;
    font-weight: 300;
    color: #e6e6e6;
  }

  .todoapp h1 {
    position: absolute;
    top: -155px;
    width: 100%;
    font-size: 100px;
    font-weight: 100;
    text-align: center;
    color: rgba(175, 47, 47, 0.15);
    -webkit-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    text-rendering: optimizeLegibility;
  }

  .info {
    margin: 65px auto 0;
    color: #bfbfbf;
    font-size: 10px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    text-align: center;
  }

  .info p {
    line-height: 1;
  }

  .info a {
    color: inherit;
    text-decoration: none;
    font-weight: 400;
  }

  .info a:hover {
    text-decoration: underline;
  }

  /* The side navigation menu */
  .sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #111; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  }

  .topnav {
    height: 200px; /* 100% Full-height */
    width: 80vw; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 21vw;
    background-color: white; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  }
  
  /* .topnav .welcomeContainer
    {
        left: 0; 
        top: 20; 
        position: 'absolute'; 
        flex-direction: 'column'; 
        justify-content: 'flex-start'; 
        align-items: 'flex-start'; 
        gap: 16; 
        display: 'inline-flex';
    }
  
    .topnav .welcomeContainer .dashboard
        {
            color: '#8C8E8E'; 
            font-size: 12; 
            font-family: 'SF Pro'; 
            font-weight: '400'; 
            line-height: 14.40; 
            word-wrap: 'break-word';
        }

        .topnav .welcomeContainer .welcomeText
        {
            color: '#04334E'; 
            font-size: 32; 
            font-family: 'SF Pro'; 
            font-weight: '510'; 
            line-height: 38.40; 
            word-wrap: 'break-word' 
        } */

  /* The navigation menu links */
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  /* Position and style the close button (top right corner) */
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  /* Style page content - use this if you want to push the page content to the right when you open the side navigation */
  #main {
    transition: margin-left .5s;
    padding: 20px;
  }
  
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }

`

export const base = {
  textInput: css`
      position: relative;
      margin: 0;
      width: 100%;
      font-size: 24px;
      font-family: inherit;
      font-weight: inherit;
      line-height: 1.4em;
      color: inherit;
      padding: 6px;
      border: 1px solid #999;
      box-shadow: inset 0 -1px 5px 0 rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `,

  button: css`
        border: 1px;
        border-color: #f5080a;
        border-width: 1px;
        color: white;
        background-color: rgb(32 38 72);
        padding: 5px;
        width: 100px;
        text-align: center;
        text-decoration: none;

        `,
}
