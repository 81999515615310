import React from "react";
import { svgimages } from "../../../../utils/svgimages";
import { NavItem } from "../../../../types/navItem";
import { Badge, ListItemIcon, ListItemText, MenuItem } from "@mui/material";

export default function MenuBarItem({
  menuBarItem,
  itemSelectedHandler,
  isSelected,
}: {
  menuBarItem: NavItem;
  itemSelectedHandler: any;
  isSelected: boolean;
}) {
  return (
    <MenuItem
      sx={{ paddingLeft: 5, bgcolor: isSelected ? "#FCF3ED" : "white" }}
      selected={isSelected}
      onClick={() => itemSelectedHandler(menuBarItem)}
    >
      <ListItemIcon>
        {menuBarItem.name === "Updates" && menuBarItem.alert ? (
          <Badge color="success" variant="dot">
            <img
              alt="selected-item"
              src={svgimages[menuBarItem.icon]}
              className="selecteditem.icon"
            />
          </Badge>
        ) : (
          <img
            alt="selected-item"
            src={svgimages[menuBarItem.icon]}
            className="selecteditem.icon"
          />
        )}
      </ListItemIcon>
      <ListItemText>{menuBarItem.name}</ListItemText>
    </MenuItem>
  );
}
