import {
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { ClientDetails } from "../../types/clientDetails";
import EditModal from "./Modal/component";

export default function EditDetails({
  clientDetails,
  setClientDetails,
}: {
  clientDetails: ClientDetails;
  setClientDetails: (clientDetails: ClientDetails) => void;
}) {
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>("");
  const [editDisplayName, setEditDisplayName] = useState<string>("");
  const [editName, setEditName] = useState<string>("");

  const handleEditClick = (
    value: string,
    displayName: string,
    name: string
  ) => {
    setEditValue(value);
    setEditDisplayName(displayName);
    setEditName(name);
    setOpenEditModal(true);
  };

  return (
    <Box bgcolor="#E6EBED">
      <Box padding={2}>
        <Box
          sx={{ borderColor: "#E6EBED" }}
          borderBottom={1}
          bgcolor="white"
          textAlign="left"
        >
          <Box padding={3}>
            <Typography paddingLeft={2} fontWeight={"bold"} fontSize={14}>
              Your profile
            </Typography>
          </Box>
        </Box>
        <Box bgcolor="white" textAlign="left">
          <Box padding={3} maxWidth="75%">
            <Box sx={{ borderColor: "#E6EBED" }} borderBottom={1}>
              <Box padding={2}>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography fontWeight={"bold"}>Full Name</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography color="#8199A6">
                      {clientDetails?.full_name || ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Link
                      data-testid="edit-full-name"
                      fontSize={12}
                      component="button"
                      color="rgba(222, 134, 70, 1);"
                      onClick={() =>
                        handleEditClick(
                          clientDetails?.full_name || "",
                          "Full Name",
                          "full_name"
                        )
                      }
                    >
                      Edit
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ borderColor: "#E6EBED" }} borderBottom={1}>
              <Box padding={2}>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography fontWeight={"bold"}>Email</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography color="#8199A6">
                      {clientDetails?.email || ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ borderColor: "#E6EBED" }} borderBottom={1}>
              <Box padding={2}>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography fontWeight={"bold"}>Phone number</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography color="#8199A6">
                      {clientDetails?.phone || ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Link
                      data-testid="edit-phone-number"
                      fontSize={12}
                      component="button"
                      color="rgba(222, 134, 70, 1);"
                      onClick={() =>
                        handleEditClick(
                          clientDetails?.phone || "",
                          "Phone Number",
                          "phone"
                        )
                      }
                    >
                      Edit
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ borderColor: "#E6EBED" }} borderBottom={1}>
              <Box padding={2}>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography fontWeight={"bold"}>Password</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography color="#8199A6">********</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Link
                      data-testid="edit-password"
                      fontSize={12}
                      component="button"
                      color="rgba(222, 134, 70, 1);"
                      onClick={() =>
                        handleEditClick("", "your password", "password")
                      }
                    >
                      Edit
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ borderColor: "#E6EBED" }}
          paddingTop={5}
          borderBottom={1}
          bgcolor="white"
          textAlign="left"
        >
          <Box padding={3}>
            <Typography paddingLeft={2} fontWeight={"bold"} fontSize={14}>
              Notification preferences
            </Typography>
          </Box>
        </Box>
        <Box bgcolor="white" textAlign="left">
          <Box padding={3}>
            <Box paddingLeft={2}>
              <FormControl>
                <RadioGroup name="radio-buttons-group">
                  <FormControlLabel
                    value={clientDetails?.phone || ""}
                    control={
                      <Radio
                        onClick={() =>
                          handleEditClick(
                            "mobile",
                            "",
                            "notification_preference"
                          )
                        }
                        checked={
                          clientDetails.notification_preference === "mobile"
                        }
                        sx={{
                          color: "#E6EBED",
                          "&.Mui-checked": {
                            color: "#DE8646",
                          },
                        }}
                      />
                    }
                    label={"Phone - (" + (clientDetails?.phone || "") + ")"}
                  />
                  <FormControlLabel
                    value={clientDetails?.email || ""}
                    control={
                      <Radio
                        onClick={() =>
                          handleEditClick(
                            "email",
                            "",
                            "notification_preference"
                          )
                        }
                        checked={
                          clientDetails.notification_preference !== "mobile"
                        }
                        sx={{
                          color: "#E6EBED",
                          "&.Mui-checked": {
                            color: "#DE8646",
                          },
                        }}
                      />
                    }
                    label={"Email - (" + (clientDetails?.email || "") + ")"}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ borderColor: "#E6EBED" }}
          paddingTop={5}
          borderBottom={1}
          bgcolor="white"
          textAlign="left"
        >
          <Box padding={3}>
            <Typography paddingLeft={2} fontWeight={"bold"} fontSize={14}>
              Your case
            </Typography>
          </Box>
        </Box>
        <Box bgcolor="white" textAlign="left">
          <Box padding={3} maxWidth="75%">
            <Box sx={{ borderColor: "#E6EBED" }} borderBottom={1} padding={2}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography fontWeight="bold">Case ID</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography color="#8199A6">
                    {clientDetails?.case_reference || ""}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ borderColor: "#E6EBED" }} borderBottom={1} padding={2}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography fontWeight="bold">Your Case Manager</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Box>
                    <Typography color="#8199A6">
                      {clientDetails?.case_manager_name || ""}
                    </Typography>
                    <Link
                      fontSize={14}
                      component="button"
                      color="rgba(222, 134, 70, 1);"
                    >
                      {clientDetails.case_manager_email || ""}
                    </Link>
                    <Typography marginTop={1} color="#8199A6">
                      {clientDetails.case_manager_phone || ""}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ borderColor: "#E6EBED" }} borderBottom={1} padding={2}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography fontWeight="bold">Your Agent</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Box>
                    <Typography color="#8199A6">
                      {clientDetails?.solicitor_name || ""}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      <EditModal
        clientDetails={clientDetails}
        setClientDetails={setClientDetails}
        displayName={editDisplayName}
        name={editName}
        value={editValue}
        open={openEditModal}
        setOpen={setOpenEditModal}
      />
    </Box>
  );
}
