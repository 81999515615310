import axios from "axios";
import Cookies from "universal-cookie";
import { UserData } from "../types/user";

const clientApiUrl =
  process.env.REACT_APP_CLIENT_API_URL || "https://clients-uat.novex.software";

const presignUpload = async (
  name: string,
  contentType: string
): Promise<any> => {
  const userDetails = JSON.parse(
    localStorage.getItem("user") || "{}"
  ) as UserData;

  try {
    const response = await axios.post(
      `${clientApiUrl}/matters/${userDetails.matter_id}/presign-upload?name=${name}`,
      {},
      {
        headers: {
          "Content-Type": contentType,
          Authorization: `Bearer ${new Cookies().get("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

export default presignUpload;
