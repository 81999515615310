import React from "react";

import { Box, Chip, Divider, Link, Typography } from "@mui/material";
import { ClientDetails } from "../../../types/clientDetails";
import processData from "../../../Config/process.json";
import { Theme, useTheme } from "@mui/system";

const styles = (theme: Theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    borderColor: "#E6EBED",
    borderShadow: 1,
    [theme.breakpoints.up("xl")]: {
      height: "4vh",
    },
    [theme.breakpoints.down("xl")]: {
      height: "7vh",
    },
    [theme.breakpoints.down("lg")]: {
      height: "8.5vh",
    },
  },
});

export default function Header({
  clientDetails,
  caseStatus,
}: {
  clientDetails: ClientDetails;
  caseStatus: number;
}) {
  const classes = styles(useTheme());

  return (
    <Box
      paddingLeft={5}
      textAlign={"left"}
      sx={classes.root}
      borderBottom={1}
      paddingTop={1}
    >
      <Box display="inline-flex">
        <Box display="inline-flex" marginLeft={2}>
          <Typography color="gray" fontSize={12} sx={{ flexGrow: 1 }}>
            Case ID: &nbsp;
          </Typography>
          <Typography fontWeight="bold" fontSize={12} sx={{ flexGrow: 1 }}>
            {clientDetails?.case_reference || ""}
          </Typography>
          <Divider sx={{ marginLeft: 2 }} orientation="vertical" flexItem />
        </Box>
        <Box marginLeft={2} display="inline-flex">
          <Typography color="gray" fontSize={12} sx={{ flexGrow: 1 }}>
            Case Status: &nbsp;
          </Typography>
          <Chip
            sx={{ fontSize: 12, backgroundColor: "#FCF3ED" }}
            size="small"
            label={
              processData
                .find((item) => item.id === caseStatus)
                ?.title.toUpperCase() || ""
            }
          />
          <Divider sx={{ marginLeft: 2 }} orientation="vertical" flexItem />
        </Box>
        <Box marginLeft={2} display="inline-flex">
          <Typography color="gray" fontSize={12} sx={{ flexGrow: 1 }}>
            Case Manager: &nbsp;{" "}
            {clientDetails?.case_manager_name && (
              <Link
                component="button"
                color="rgba(222, 134, 70, 1);"
                onClick={() => {
                  window
                    .open(
                      `https://booking.equilaw.uk.com/${clientDetails?.case_manager_name?.replaceAll(
                        " ",
                        ""
                      )}`,
                      "_blank"
                    )
                    ?.focus();
                }}
              >
                {clientDetails?.case_manager_name || ""}
              </Link>
            )}
          </Typography>
          <Divider sx={{ marginLeft: 2 }} orientation="vertical" flexItem />
        </Box>
        <Box marginLeft={2} display="inline-flex">
          <Typography color="gray" fontSize={12} sx={{ flexGrow: 1 }}>
            Agent: &nbsp;{" "}
            <Link color="rgba(222, 134, 70, 1);" href="#">
              {clientDetails?.solicitor_name || ""}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
