import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { UserData } from "../../../../types/user";
import Cookies from "universal-cookie";
import updateRefPaths from "../../../../utils/updateReferentialPaths";

const clientApiUrl =
  process.env.REACT_APP_CLIENT_API_URL || "https://clients-uat.novex.software";

const Signature = ({
  handleNextClick,
  isOwner,
  nOwners,
  nClients,
}: {
  handleNextClick: (e: any) => void;
  isOwner?: boolean;
  nOwners?: number;
  nClients?: number;
}) => {
  const [sigPad, setSigPad] = useState<any>({});
  const [sig2Pad, setSig2Pad] = useState<any>({});
  const [complete, setComplete] = useState<boolean>(false);
  const [currOwner, setCurrOwner] = useState<number>(1);
  const [ownerName, setOwnerName] = useState<string>("");
  const [percentage, setPercentage] = useState<number>(0);

  const handleSubmit = async (e: any) => {
    const imgUrl = sigPad
      .getTrimmedCanvas()
      .toDataURL("image/png")
      .replaceAll("data:image/png;base64,", "")
      .replaceAll("=", "");

    const userDetails = JSON.parse(
      localStorage.getItem("user") || "{}"
    ) as UserData;

    const name = isOwner
      ? "Owner " + currOwner + " Signature"
      : "Client Signature";

    await axios.post(
      `${clientApiUrl}/matters/${userDetails.matter_id}/signature?name=${name}`,
      unescape(encodeURIComponent(imgUrl)),
      {
        headers: { Authorization: `Bearer ${new Cookies().get("token")}` },
      }
    );

    if (!isOwner && nClients && nClients > 1) {
      const imgUrl = sig2Pad
        .getTrimmedCanvas()
        .toDataURL("image/png")
        .replaceAll("data:image/png;base64,", "")
        .replaceAll("=", "");

      const userDetails = JSON.parse(
        localStorage.getItem("user") || "{}"
      ) as UserData;

      const name = "Client 2 Signature";

      await axios.post(
        `${clientApiUrl}/matters/${userDetails.matter_id}/signature?name=${name}`,
        unescape(encodeURIComponent(imgUrl)),
        {
          headers: { Authorization: `Bearer ${new Cookies().get("token")}` },
        }
      );
    }

    if (isOwner) {
      if (currOwner === 1) {
        updateRefPaths(
          userDetails.matter_id,
          "joint_property_information[0].owner_name",
          ownerName
        );
        updateRefPaths(
          userDetails.matter_id,
          "joint_property_information[0].percentage",
          percentage
        );
      }
      if (currOwner === 2) {
        updateRefPaths(
          userDetails.matter_id,
          "joint_property_information[1].owner_name",
          ownerName
        );
        updateRefPaths(
          userDetails.matter_id,
          "joint_property_information[1].percentage",
          percentage
        );
      }
      if (currOwner === 3) {
        updateRefPaths(
          userDetails.matter_id,
          "joint_property_information[2].owner_name",
          ownerName
        );
        updateRefPaths(
          userDetails.matter_id,
          "joint_property_information[2].percentage",
          percentage
        );
      }
      if (currOwner === 4) {
        updateRefPaths(
          userDetails.matter_id,
          "joint_property_information[3].owner_name",
          ownerName
        );
        updateRefPaths(
          userDetails.matter_id,
          "joint_property_information[3].percentage",
          percentage
        );
      }

      if (currOwner === nOwners) {
        handleNextClick(e);
        return;
      }

      setCurrOwner(currOwner + 1);
      setOwnerName("");
      setPercentage(0);
      setSigPad(sigPad.clear());
    }

    if (!isOwner || nOwners === currOwner) {
      handleNextClick(e);
    }
  };

  return (
    <Box>
      <Typography>
        As we are acting on your behalf for your Equity Release we will need a
        copy of your signature so that we can process certain documentation for
        you. Please draw your signature in the box below to enable us to do so.
      </Typography>
      {isOwner && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              value={ownerName}
              onChange={(e) => {
                setOwnerName(e.target.value);
              }}
              sx={{ marginTop: 2 }}
              fullWidth
              label={`Owner ${currOwner} Name`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={percentage}
              onChange={(e) => {
                setPercentage(Number(e.target.value));
              }}
              sx={{ marginTop: 2 }}
              fullWidth
              type="number"
              label={`Owner ${currOwner} Ownership Percentage`}
            />
          </Grid>
        </Grid>
      )}
      <Box display="flex">
        <Box marginTop={2} border={1} borderColor="black" width={400}>
          <Typography>Client 1 Signature</Typography>
          <SignatureCanvas
            penColor="black"
            canvasProps={{ width: 400, height: 200, className: "sigCanvas" }}
            ref={(ref) => {
              setSigPad(ref);
            }}
          />
        </Box>
        {!isOwner && nClients && nClients > 1 && (
          <Box
            marginLeft={2}
            marginTop={2}
            border={1}
            borderColor="black"
            width={400}
          >
            <Typography>Client 2 Signature</Typography>
            <SignatureCanvas
              penColor="black"
              canvasProps={{ width: 400, height: 200, className: "sigCanvas" }}
              ref={(ref) => {
                setSig2Pad(ref);
              }}
            />
          </Box>
        )}
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={complete}
            onChange={() => {
              setComplete(!complete);
            }}
          />
        }
        label="Please tick this checkbox when you have finished your signature"
      />
      <br />
      <Button
        variant="text"
        sx={{
          textTransform: "outlined",
          marginTop: 2,
          marginRight: 2,
        }}
        onClick={() => {
          setSigPad(sigPad.clear());
          setSig2Pad(sig2Pad.clear());
          setComplete(false);
        }}
      >
        Reset
      </Button>
      {isOwner && nOwners !== currOwner ? (
        <Button
          variant="contained"
          sx={{
            bgcolor: "#DE8646",
            textTransform: "none",
            marginTop: 2,
          }}
          onClick={handleSubmit}
          disabled={!complete}
        >
          Next Owner
        </Button>
      ) : (
        <Button
          variant="contained"
          sx={{
            bgcolor: "#DE8646",
            textTransform: "none",
            marginTop: 2,
          }}
          onClick={handleSubmit}
          disabled={!complete}
        >
          Save & Complete
        </Button>
      )}
    </Box>
  );
};

export default Signature;
