import React, { useState, useEffect } from "react";
import { FormItem } from "../../../types/formTemplate";
import FormItemComponent from "../FormItem/component";
import FormTemplateService from "../../../services/FormTemplateService";
import { getCurrentValuesData } from "../../../services/FormTemplateApiCalls";

import {
  FormElementDisplay,
  CurrentValueModel,
} from "../../../types/formTemplate";
import "./style.scss";
// import styled from 'styled-components'
import { Box } from "@mui/system";
import { Button, Grid, Link, Typography } from "@mui/material";
import Summary from "./Summary/component";
import { UserData } from "../../../types/user";
import { submitForm } from "../../../utils/submitForm";
import receiveChecklistItem from "../../../utils/receiveChecklistItem";
import { NavItem } from "../../../types/navItem";
import Signature from "./Signature/component";
import sendPurchaseCaseEmail from "../../../utils/purchaseCase";

export default function Form({
  setCurrentElement,
  service,
  startQuestion,
  category,
  handleCategoryChanged,
  handleUpdateQuestions,
  loadClientDetails,
}: {
  setCurrentElement: (item: NavItem) => void;
  service: FormTemplateService;
  startQuestion: number;
  category: number;
  handleCategoryChanged: any;
  handleUpdateQuestions: any;
  loadClientDetails: () => void;
}) {
  const [formElementDisplay, setFormElementDisplay] = useState<
    FormElementDisplay[]
  >([]);
  const [formItems, setFormItems] = useState<FormItem[] | null>(null);
  const [prevFormItem, setPrevFormItem] = useState<FormItem>();
  const [formItem, setFormItem] = useState<FormItem>();
  const [currentQuestion, setCurrentQuestion] = useState<number>(startQuestion);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [formTemplateService, setFormTemplateService] =
    useState<FormTemplateService | null>(service);
  const [currentValues, setCurrentValues] = useState<
    CurrentValueModel[] | null
  >(null);
  const [losingFocus, setLosingFocus] = useState<boolean>(false);
  const [action, setAction] = useState<string>("");
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [getSignature, setGetSignature] = useState<boolean>(false);
  const [purchaseCase, setPurchaseCase] = useState<boolean>(false);
  const [numberOfOwners, setNumberOfOwners] = useState<number>(0);
  const [nClients, setNClients] = useState<number>(1);

  const handleNextClick = async (e: React.MouseEvent<Element, MouseEvent>) => {
    console.log("Next Button Clicked");
    if (showSummary) {
      if (category === formElementDisplay?.length - 1) {
        if (
          (formTemplateService?.formTemplateData?.form_template_name ===
            "IQ2" ||
            formTemplateService?.formTemplateData?.form_template_name ===
              "Bridging - IQ" ||
            formTemplateService?.formTemplateData?.form_template_name ===
              "Gifting_Letter_Questionnaire" ||
            formTemplateService?.formTemplateData
              ?.form_template_version_name ===
              "Joint Property Information Questionnaire") &&
          !getSignature
        ) {
          setGetSignature(true);
          return;
        }

        await handleSubmitForm();
        await loadClientDetails();
        setGetSignature(false);
        return;
      }

      // Next category
      category++;

      const frmItems = await formTemplateService!.getFormItems(category);
      setFormItems(frmItems);
      setCurrentQuestion(0);
      handleCategoryChanged(category);

      setTimeout(() => {
        setShowSummary(false);
      }, 200);
    } else {
      nextStep();
    }
  };

  const handlePreviousClick = (
    e: React.MouseEvent<Element, MouseEvent>
  ): void => {
    if (purchaseCase) {
      setPurchaseCase(false);
      return;
    }
    console.log("Previous Button Clicked");
    if (getSignature) {
      setGetSignature(false);
      return;
    }
    if (showSummary) {
      setFormItem(prevFormItem);
      setShowSummary(false);
      return;
    }
    prevStep();
  };

  const handleSubmitForm = async () => {
    setDisableSubmit(true);
    const userDetails = JSON.parse(
      localStorage.getItem("user") || "{}"
    ) as UserData;

    const formTemplateVersionId = formTemplateService!.formTemplateData
      ?.form_template_version_id as number;

    const data = await getCurrentValuesData(formTemplateVersionId);

    await submitForm(
      data,
      userDetails.matter_id,
      formTemplateService!.formTemplateData?.form_template_name || ""
    );

    await receiveChecklistItem(
      formTemplateService?.formTemplateData?.form_template_name === "IQ2" ||
        formTemplateService?.formTemplateData?.form_template_name ===
          "Bridging - IQ"
        ? "Initial Questionnaire"
        : formTemplateService?.formTemplateData?.form_template_version_name ||
            "",
      ""
    );

    setCurrentElement({ name: "Home", icon: "home", id: 0 });
    setDisableSubmit(false);
  };

  const handleSaved = async (changedItem: any) => {
    let item = changedItem as FormItem;
    console.log("Item Saved {0}", item.AnswerValue);
    if (item.AnswerValue?.includes("Purchasing this property")) {
      sendPurchaseCaseEmail();
      setPurchaseCase(true);
      return;
    }
    if (formItems) {
      let index = formItems.findIndex(
        (fi) => fi.FullReferentialPath === item.FullReferentialPath
      );
      if (index > -1) {
        formItems[index] = item;
      }
    }

    if (formItem?.ItemType === 3 || formItem?.ItemType === 4) {
      handleUpdateQuestions(formItem);
    }

    if (action === "next") {
      if (showSummary) {
        setShowSummary(false);

        return;
      }

      await formTemplateService!.processVariables();

      let formTemplateVersionId = formTemplateService!.formTemplateData
        ?.form_template_version_id as number;

      let currentValuesData = await getCurrentValuesData(formTemplateVersionId);
      let currentValues = await formTemplateService!.processCurrentValues(
        currentValuesData
      );

      setCurrentValues(currentValues);
      await formTemplateService!.processFormTemplate();
      await formTemplateService!.setCurrentValues(currentValues);
      await formTemplateService!.insertCurrentValues(currentValues);

      const frmItems = await formTemplateService!.getFormItems(category);
      setFormItems(frmItems);

      // Next question
      if (currentQuestion < frmItems!.length - 1) {
        setTimeout(async () => {
          setCurrentQuestion(currentQuestion + 1);
        }, 500);
      } else if (frmItems!.filter((fi) => fi.ItemType === 8).length === 0) {
        setShowSummary(true);
        setAction("");
      } else {
        // Next category
        category++;

        setCurrentQuestion(0);
        handleCategoryChanged(category);
      }
    } else if (action === "previous") {
      if (currentQuestion > 0) {
        setCurrentQuestion(currentQuestion - 1);
      } else {
        category--;
        const frmItems = await formTemplateService!.getFormItems(category);
        setFormItems(frmItems);
        setCurrentQuestion(frmItems.length - 1);
        handleCategoryChanged(category);
      }
    }

    setLosingFocus(false);
  };

  const nextStep = () => {
    setAction("next");
    setLosingFocus(true);
  };

  const prevStep = () => {
    setAction("previous");
    setLosingFocus(true);
  };

  console.log("Current Question: " + currentQuestion);

  const fetchData = async () => {
    if (formTemplateService) {
      await formTemplateService.processVariables();

      let formTemplateVersionId = formTemplateService.formTemplateData
        ?.form_template_version_id as number;

      let currentValuesData = await getCurrentValuesData(formTemplateVersionId);
      let currentValues = await formTemplateService.processCurrentValues(
        currentValuesData
      );
      if (
        currentValuesData["Y2xpZW50cw"] &&
        currentValuesData["Y2xpZW50cw"].length > 1 &&
        currentValuesData["Y2xpZW50cw"][1]["Zmlyc3RfbmFtZQ"] !== ""
      ) {
        setNClients(2);
      }
      if (
        currentValuesData[
          "am9pbnRfcHJvcGVydHlfaW5mb3JtYXRpb25bMF0udG90YWxfbnVtYmVyX29mX293bmVycw"
        ]
      ) {
        if (
          parseInt(
            currentValuesData[
              "am9pbnRfcHJvcGVydHlfaW5mb3JtYXRpb25bMF0udG90YWxfbnVtYmVyX29mX293bmVycw"
            ]
          ) !== numberOfOwners
        ) {
          setNumberOfOwners(
            parseInt(
              currentValuesData[
                "am9pbnRfcHJvcGVydHlfaW5mb3JtYXRpb25bMF0udG90YWxfbnVtYmVyX29mX293bmVycw"
              ]
            )
          );
        }
      }

      setCurrentValues(currentValues);
      await formTemplateService.processFormTemplate();
      await formTemplateService.setCurrentValues(currentValues);
      await formTemplateService.insertCurrentValues(currentValues);

      let cats = await formTemplateService.getCategories();
      setFormElementDisplay(cats!);
      const frmItems = await formTemplateService.getFormItems(category);
      // setCurrentQuestion(startQuestion);

      setFormItems(frmItems);
      setFormItem(frmItems[currentQuestion]);
      setPrevFormItem(frmItems[currentQuestion]);
    } else {
      return null;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (formItems) {
      setFormItem(formItems[currentQuestion]);
      setPrevFormItem(formItems[currentQuestion]);
    }
  }, [currentQuestion]);

  useEffect(() => {
    setCurrentQuestion(startQuestion);
  }, [startQuestion]);

  useEffect(() => {
    console.log("Category: " + category);
    fetchData();
  }, [category]);

  return (
    <Box bgcolor="white" textAlign="left">
      <Box padding={3} maxWidth="75%">
        {formItem && (
          <Box sx={{ color: "#E6EBED" }} paddingBottom={2} borderBottom={1}>
            {getSignature ? (
              <Signature
                isOwner={
                  formTemplateService?.formTemplateData
                    ?.form_template_version_name ===
                  "Joint Property Information Questionnaire"
                }
                handleNextClick={handleNextClick}
                nOwners={numberOfOwners}
                nClients={nClients}
              />
            ) : purchaseCase ? (
              <Box>
                <Typography fontSize={16}>
                  Thank you for confirming you are purchasing this property. Due
                  to the additional requirements involved, we will need to refer
                  your file to our Business Development Team to provide you with
                  a quote before we continue further.
                </Typography>
                <Typography fontSize={16} marginTop={2}>
                  Our Business Development Team will therefore be in touch with
                  you shortly.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#DE8646",
                    textTransform: "none",
                    marginTop: 2,
                  }}
                  onClick={() => {
                    setPurchaseCase(false);
                  }}
                >
                  Go back
                </Button>
              </Box>
            ) : showSummary ? (
              <Summary
                readonly={false}
                formItems={formItems || []}
                setFormItem={setFormItem}
                handleSaved={handleSaved}
              />
            ) : (
              <FormItemComponent
                setDisableSubmit={setDisableSubmit}
                item={formItem! as any}
                losingFocus={losingFocus}
                setLosingFocus={setLosingFocus}
                handleSaved={handleSaved}
              />
            )}
          </Box>
        )}
        {!getSignature && !purchaseCase && (
          <Box padding={2}>
            <Grid container spacing={2}>
              {currentQuestion > 0 && (
                <Grid item xs={4}>
                  <Link
                    component="button"
                    onClick={handlePreviousClick}
                    color="rgba(222, 134, 70, 1);"
                  >
                    Previous question
                  </Link>
                </Grid>
              )}
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#DE8646",
                    textTransform: "none",
                  }}
                  onClick={handleNextClick}
                  disabled={disableSubmit}
                >
                  {showSummary
                    ? category === formElementDisplay?.length - 1
                      ? "Save & Complete"
                      : "Save & Continue"
                    : "Next question"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
}
