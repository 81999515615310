import React, { useState, useEffect } from "react";
import { ChecklistItem } from "../../types/checklist.d";
import getChecklist from "../../services/ChecklistService";
import OutstandingTaskItem from "./OutstandingTaskItem/component";
import { Box } from "@mui/system";
import { Alert, Grid, Link, Snackbar, Typography } from "@mui/material";
import {
  FiberManualRecord,
  Notifications,
  TaskAlt,
  Warning,
} from "@mui/icons-material";
import Updates from "../../types/updates";
import CompletedTaskItem from "./CompletedTaskItem/component";

const displayDate = (date: Date): string => {
  const zeroPad = (num: number, places: number) =>
    String(num).padStart(places, "0");

  return `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()} ${zeroPad(date.getHours(), 2)}:${zeroPad(
    date.getMinutes(),
    2
  )}`;
};

export default function OutstandingTasks({
  items,
  menuClickHandler,
  updates,
  loadUpdates,
  initFormTemplateService,
  setReadonly,
  bridging,
}: {
  items: ChecklistItem[];
  menuClickHandler: any;
  updates: Updates[];
  loadUpdates: () => void;
  initFormTemplateService: (name: string) => void;
  setReadonly: (readonly: boolean) => void;
  bridging?: boolean;
}) {
  const [checklistData, setChecklistData] = useState<ChecklistItem[]>([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const upload_clickHandler = (message: string) => {
    alert(message);
  };

  const form_clickHandler = async (name: string) => {
    // alert(message);
    let navItem = { id: 7, name: "Form", icon: "transaction" };
    await initFormTemplateService(name);

    menuClickHandler(navItem);
  };

  const read_clickHandler = (item: ChecklistItem) => {
    alert(item.name);
  };

  useEffect(() => {
    if (items.length === 0) {
      getChecklist().then((data) => {
        if (data) {
          setChecklistData(data);
        }
      });
    } else {
      setChecklistData(items);
    }
    //eslint-disable-next-line
  }, []);

  if (!checklistData) {
    return <h2>Checklist Not Found</h2>;
  }

  return (
    <Box bgcolor="#E6EBED">
      <Box padding={2}>
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            <Box bgcolor="white" textAlign="left">
              <Box padding={3}>
                <Typography>
                  Welcome to our {bridging ? "bridging loan" : "equity release"}{" "}
                  application platform. Please complete the following tasks as
                  soon as possible so we can begin processing your application.
                </Typography>
              </Box>
            </Box>
            <Box marginTop={2} bgcolor="white" textAlign="left">
              <Box
                sx={{
                  borderColor: "#E6EBED",
                  display: "inline-flex",
                  width: "100%",
                }}
                borderBottom={1}
                padding={3}
              >
                <Warning />
                <Typography fontWeight={"bold"} marginLeft={3}>
                  Outstanding Tasks:
                </Typography>
              </Box>
              <Box padding={3} paddingTop={0}>
                {checklistData
                  ?.filter(
                    (item) =>
                      item.document_received === "" || !item.document_received
                  )
                  ?.map((item, index) => (
                    <OutstandingTaskItem
                      setSnackbarOpen={setSnackbarOpen}
                      setReadonly={setReadonly}
                      setChecklistData={setChecklistData}
                      loadUpdates={loadUpdates}
                      key={index}
                      item={item}
                      index={index}
                      upload_clickHandler={upload_clickHandler}
                      form_clickHandler={form_clickHandler}
                      read_clickHandler={read_clickHandler}
                    />
                  ))}
                {!checklistData ||
                  (checklistData?.filter(
                    (item) =>
                      item.document_received === "" || !item.document_received
                  ).length === 0 && (
                    <Typography marginTop={2} color="#8199A6">
                      You currently have no outstanding tasks. Please check for
                      further updates.
                    </Typography>
                  ))}
              </Box>
            </Box>
            {checklistData?.filter(
              (item) => item.document_received && item.document_received !== ""
            ).length > 0 && (
              <Box marginTop={2} bgcolor="white" textAlign="left">
                <Box
                  sx={{
                    borderColor: "#E6EBED",
                    display: "inline-flex",
                    width: "100%",
                  }}
                  borderBottom={1}
                  padding={3}
                >
                  <TaskAlt />
                  <Typography fontWeight={"bold"} marginLeft={3}>
                    Completed Tasks:
                  </Typography>
                </Box>
                <Box padding={3} paddingTop={0}>
                  {checklistData
                    ?.filter(
                      (item) =>
                        item.document_received && item.document_received !== ""
                    )
                    ?.map((item, index) => (
                      <CompletedTaskItem
                        form_clickHandler={form_clickHandler}
                        setReadonly={setReadonly}
                        item={item}
                        key={index}
                      />
                    ))}
                  {checklistData?.filter(
                    (item) =>
                      item.document_received && item.document_received !== ""
                  ).length === 0 && (
                    <Typography marginTop={2} color="#8199A6">
                      You currently have no completed tasks. Please check for
                      further updates.
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Box bgcolor="white" textAlign="left">
              <Box
                sx={{
                  borderColor: "#E6EBED",
                  display: "inline-flex",
                  width: "100%",
                }}
                borderBottom={1}
                padding={3}
              >
                <Notifications />
                <Typography fontWeight={"bold"} marginLeft={3}>
                  Updates{" "}
                  <Link
                    component={"button"}
                    onClick={() => {
                      menuClickHandler({ name: "Updates" });
                    }}
                    color="rgba(222, 134, 70, 1);"
                  >
                    (view all)
                  </Link>
                </Typography>
              </Box>
              {updates?.map((item, index) => (
                <Box
                  key={index}
                  sx={{ borderColor: "#E6EBED" }}
                  borderBottom={1}
                  padding={3}
                  paddingTop={0}
                >
                  <Box padding={2} display="inline-flex">
                    <FiberManualRecord
                      fontSize="small"
                      sx={{ color: item.seen ? "#E6EBED" : "#0DBC29" }}
                    />
                    <Typography color="#8199A6" marginLeft={1}>
                      {displayDate(new Date(item.date))}
                    </Typography>
                  </Box>
                  <Box padding={2} paddingTop={0} display="inline-flex">
                    <Typography color="#8199A6" marginLeft={1}>
                      {item.content}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setSnackbarOpen(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          You have successfully uploaded your document and we will begin
          processing it shortly.
        </Alert>
      </Snackbar>
    </Box>
  );
}
