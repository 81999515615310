import React, { useEffect, useState } from "react";

import data from "../../Config/faq.json";
import bridgingdata from "../../Config/bridging-faq.json";
import { svgimages } from "../../utils/svgimages";
import { Accordion, AccordionSummary, Box, Typography } from "@mui/material";
import { Theme, useTheme } from "@mui/system";

const styles = (theme: Theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "75%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
});

export default function FAQ({ bridging }: { bridging?: boolean }) {
  const [faqData, setFaqData] = useState<any[]>([]);

  useEffect(() => {
    setFaqData(bridging ? bridgingdata : data);
  }, [bridging]);

  const classes = styles(useTheme());

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none",
          },
          ".expandIconWrapper": {
            display: "none",
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block",
          },
        }}
      >
        <div className="expandIconWrapper">
          <img
            src={svgimages["delete"]}
            alt="delete"
            className="home-svg073delete"
          />
        </div>
        <div className="collapsIconWrapper">
          <img
            src={svgimages["add"]}
            alt="SVG0737add"
            className="home-svg073add"
          />
        </div>
      </Box>
    );
  };

  if (!faqData) {
    return <h2>FAQ Not Found</h2>;
  }

  return (
    <Box bgcolor="#E6EBED">
      <Box padding={2}>
        <Box
          sx={{ borderColor: "#E6EBED" }}
          borderBottom={1}
          bgcolor="white"
          textAlign="left"
        >
          <Box padding={3}>
            <Typography fontWeight={"bold"} fontSize={14}>
              Frequently Asked Questions
            </Typography>
          </Box>
        </Box>
        <Box bgcolor="white" textAlign="left">
          <Box padding={3} sx={classes.container}>
            {faqData.map((item, index) => (
              <Accordion
                sx={{
                  marginBottom: 1,
                  boxShadow: "none",
                }}
                key={index}
              >
                <AccordionSummary
                  expandIcon={CustomExpandIcon()}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography fontWeight="bold" fontSize={14}>
                    Q: {item.question}
                  </Typography>
                </AccordionSummary>
                <Box padding={3}>
                  <Typography
                    whiteSpace={"pre-line"}
                    color="#8199A6"
                    fontSize={14}
                  >
                    <span dangerouslySetInnerHTML={{ __html: item.answer }} />
                  </Typography>
                </Box>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
