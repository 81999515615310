import React from "react";
import { FormElementDisplay } from "../../../types/formTemplate";
import { Box, Typography } from "@mui/material";
import "./style.scss";
import { MouseEventHandler } from "react";
import { Theme, useTheme } from "@mui/system";

const styles = (theme: Theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    color: "#E6EBED",
  },
});

export default function ProgressView({
  items,
  current,
  handleCategorySelectClick,
}: {
  items: FormElementDisplay[];
  current: FormElementDisplay;
  handleCategorySelectClick: any;
}) {
  const classes = styles(useTheme());

  const [currentItem, setCurrentItem] =
    React.useState<FormElementDisplay>(current);

  React.useEffect(() => {
    setCurrentItem(current);
  }, [current]);

  const handleClick =
    (index: number) => (e: React.MouseEvent<Element, MouseEvent>) => {
      setCurrentItem(items[index]);
      handleCategorySelectClick(index);
    };

  return (
    <Box bgcolor="white" sx={classes.root} borderBottom={1}>
      <Box padding={4}>
        <div className="progress-container">
          {items &&
            items &&
            items.map((cat, index) => (
              <div
                className="progress-sub-container"
                onClick={handleClick(index)}
              >
                <Typography data-testid="formItemLabel" fontWeight={"bold"}>
                  {(index + 1).toString()}. {cat.label}
                </Typography>
                <div
                  className={
                    cat?.label === currentItem?.label
                      ? "progress-item-active"
                      : "progress-item"
                  }
                ></div>
              </div>
            ))}
        </div>
      </Box>
    </Box>
  );
}
