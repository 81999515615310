import { Box } from "@mui/system";
import React from "react";
import { FormItem } from "../../../../types/formTemplate";
import { Typography } from "@mui/material";
import SummaryRow from "./SummaryRow/component";
import { read } from "fs";

const Summary = ({
  readonly,
  formItems,
  setFormItem,
  handleSaved,
}: {
  readonly: boolean;
  formItems: FormItem[];
  setFormItem: (item: FormItem) => void;
  handleSaved: (item: FormItem) => void;
}): JSX.Element => {
  return (
    <Box>
      {!readonly && (
        <Typography color="#8199A6" paddingBottom={2}>
          Please confirm your information before submitting.
        </Typography>
      )}
      <Box>
        {formItems.map((item, index) => (
          <SummaryRow
            readonly={readonly}
            key={index}
            item={item}
            index={index}
            setFormItem={setFormItem}
            formItems={formItems}
            handleSaved={handleSaved}
          />
        ))}
        {readonly && <Box sx={{ minHeight: "5vh" }}></Box>}
      </Box>
    </Box>
  );
};

export default Summary;
