import React, { useState, useEffect } from "react";
import { FormItem } from "../../types/formTemplate";
import FormTemplateService from "../../services/FormTemplateService";
import { FormElementDisplay } from "../../types/formTemplate";
import Form from "../Form/Form/component";
import ProgressView from "../Form/ProgressView/component";
import { Box } from "@mui/material";
import { NavItem } from "../../types/navItem";
import FormSummary from "../Form/FormSummary/component";
import CategoryNav from "../Form/Form/CategoryNav/component";
import { getCurrentValuesData } from "../../services/FormTemplateApiCalls";

export default function FormContainer({
  service,
  setCurrentElement,
  readonly,
  loadClientDetails,
}: {
  service: FormTemplateService;
  setCurrentElement: (item: NavItem) => void;
  readonly: boolean;
  loadClientDetails: () => void;
}) {
  const [formTemplateService, setFormTemplateService] =
    useState<FormTemplateService | null>(service);
  const [startQuestion, setStartQuestion] = useState<number>(0);
  const [category, setCategory] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formElementDisplay, setFormElementDisplay] = useState<
    FormElementDisplay[]
  >([]);

  const fetchData = async () => {
    setLoading(true);
    if (formTemplateService) {
      try {
        let formTemplateVersionId = formTemplateService.formTemplateData
          ?.form_template_version_id as number;

        let currentValuesData = await getCurrentValuesData(
          formTemplateVersionId
        );
        let currentValues = await formTemplateService.processCurrentValues(
          currentValuesData
        );
        await formTemplateService.setCurrentValues(currentValues);

        await formTemplateService.processFormTemplate();
        await formTemplateService.processVariables();
        let cats = await formTemplateService.getCategories();
        setFormElementDisplay(cats!);
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (!loading) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    setFormTemplateService(service);
  }, [service]);

  const handleCategorySelectClick = (index: number) => {
    console.log("Category Selected: " + index);
    setCategory(index);
    setStartQuestion(0);
    //    setCategoryIndex(index);
  };
  const handleCategoryChanged = (index: number) => {
    console.log("Category Changed: " + index);
    setCategory(index);
    setStartQuestion(0);
  };

  const handleUpdateQuestions = (formItem: FormItem) => {
    console.log("handling update question");
    fetchData();
  };

  return (
    <Box bgcolor="#E6EBED">
      <Box padding={2}>
        {formElementDisplay && (
          <>
            <ProgressView
              items={formElementDisplay}
              current={formElementDisplay[category]}
              handleCategorySelectClick={handleCategorySelectClick}
            />
            <CategoryNav
              index={category}
              totalCategories={formElementDisplay.length}
              handleCategorySelectClick={handleCategorySelectClick}
            />
          </>
        )}
        {readonly ? (
          <FormSummary service={formTemplateService!} category={category} />
        ) : (
          <Form
            loadClientDetails={loadClientDetails}
            setCurrentElement={setCurrentElement}
            service={formTemplateService!}
            startQuestion={startQuestion}
            category={category}
            handleCategoryChanged={handleCategoryChanged}
            handleUpdateQuestions={handleUpdateQuestions}
          />
        )}
      </Box>
    </Box>
  );
}
